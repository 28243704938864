import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewCompanyFeeCityApi = async(formValues) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            Name: formValues.Name.trim().toUpperCase(),
            AddedBy: id
        }

        const res = await axios.post(`${API_URL}/api/V2/companyFeesCities/addNew/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllCompanyFeeCityApi = async() => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/companyFeesCities/allCompanyFeesCity/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

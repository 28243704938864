import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";

function OperationProducts({ selectedOp }) {
  const [selectedProducts, setSelectedProducts] = useState([])

  useEffect(() => {
    getInitialData()
  }, [selectedOp])

  const getInitialData = () => {
    try {
      const Products = []

      Object.keys(selectedOp).map(el => {
        Products.push(selectedOp[el].produits)
      })

      setSelectedProducts(Products.flat(1))

      console.log(Products.flat(1))
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <div
      style={{ height: "80vh", overflowY: "scroll" }}
    >
      {
        selectedProducts.map(el =>
          <Card className="mt-2">
            <CardBody>
              <Typography variant="h5" color="blue-gray" className="mb-2">
                {el?.name}
              </Typography>
              <Typography>
                <ul>
                  <li>
                    <p className="grid grid-cols-3 w-full md:w-1/3"><span>Quantity</span> <span>:</span> <span className="text-md font-bold text-gray-500">{parseFloat(el.quantiteByUnity).toFixed(2)}</span></p>
                  </li>
                  <li>
                    <p className="grid grid-cols-3 w-full md:w-1/3"><span>Prix</span> <span>:</span> <span className="text-md font-bold text-gray-500">{parseFloat(el.price).toFixed(2)}</span></p>
                  </li>
                  <li>
                    <p className="grid grid-cols-3 w-full md:w-1/3"><span>TVA</span> <span>:</span> <span className="text-md font-bold text-gray-500">{el.HaveTVA} %</span></p>
                  </li>
                  <li>
                    <p className="grid grid-cols-3 w-full md:w-1/3"><span>PrixTotal TTC</span> <span>:</span> <span className="text-md font-bold text-gray-500">{parseFloat(el.totalPriceTTC).toFixed(2)}</span></p>
                  </li>
                </ul>
              </Typography>
            </CardBody>
          </Card>
        )
      }
    </div>
  );
}

export default OperationProducts;

import React, { useEffect, useState } from "react";
import { Button, Spinner } from "@material-tailwind/react";

import { BsPrinterFill } from "react-icons/bs";
import { getAllProductsCategoriesApi } from "api/filtersData";
import { getAllDepotsApi } from "api/depot";
import { productInvetaireListApi } from "api/products";
import { pdfInvetaireGeneratorApi } from "api/pdf";

function ProductInventaire({ closePopup }) {
  const [productsCategories, setProductsCategories] = useState([])
  const [listOfDepots, setListOfDepots] = useState([])
  const [listOfProducts, setListOfProducts] = useState([])

  const [inputFilterValue, setInputFilterValue] = useState({
    Categorie: "",
    Depot: "",
    Product: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getInitialFilterData()
  }, [])

  useEffect(() => {
    getInitialData(inputFilterValue)
  }, [])

  const getInitialFilterData = async () => {
    try {
      const productsCategories = await getAllProductsCategoriesApi("")
      const depots = await getAllDepotsApi()

      setProductsCategories(productsCategories)
      setListOfDepots(depots)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getInitialData = async (inputFilterValue) => {
    try {
      setIsLoading(true)
      const listOfProducts = await productInvetaireListApi(inputFilterValue)
      setListOfProducts(listOfProducts)
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });

    if(name == "Categorie")
      inputFilterValue.Categorie = value
    if(name == "Depot")
      inputFilterValue.Depot = value
    if(name == "Product")
      inputFilterValue.Product = value

    getInitialData(inputFilterValue)
  }

  const handleCloseReleveOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handlePrintClientList = async () => {
    try {
      const response = await pdfInvetaireGeneratorApi(inputFilterValue)
      window.open(response, "_blank");
    }
    catch(err) {

    }
  }

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-4">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par categorie :
          </small>
          <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="Categorie"
              value={inputFilterValue.Categorie}
              onChange={handleInputChange}
            >
              <option value="">Tout</option>

              {
                productsCategories.map(el => (
                  <option>{el.Name}</option>
                ))
              }
            </select>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par dépôt :
          </small>
          <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name="Depot"
              value={inputFilterValue.Depot}
              onChange={handleInputChange}
            >
              <option value="">Tout</option>
              {
                listOfDepots.map(el => (
                  <option>{el.Name}</option>
                ))
              }
          </select>
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par produit :
          </small>
          <input
            type="text"
            id=""
            placeholder="Tout"
            className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

            name="Product"
            value={inputFilterValue.Product}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Imprimer
          </small>
          <BsPrinterFill size={50} className="cursor-pointer" onClick={handlePrintClientList}  />
        </div>
      </div>

      {
        isLoading ?
        <div className="flex w-full items-center justify-center">
        <Spinner className="h-12 w-12" />
        </div> :
        <table className="w-full">
        <thead>
          <tr>
            <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700 pt-3 pb-3">
              <p className="text-sm tracking-wide text-gray-600">Code produit</p>
            </th>
            <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700 pt-3 pb-3">
              <p className="text-sm tracking-wide text-gray-600">Produit</p>
            </th>
            <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700 pt-3 pb-3">
              <p className="text-sm tracking-wide text-gray-600">Unité</p>
            </th>
            <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700 pt-3 pb-3">
              <p className="text-sm tracking-wide text-gray-600">Dépôt</p>
            </th>
            <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700 pt-3 pb-3">
              <p className="text-sm tracking-wide text-gray-600">Disponible</p>
            </th>
          </tr>
        </thead>
        {
          inputFilterValue.Depot == "" ?
          <tbody>
          {
            listOfProducts.map(el => (
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.CodeProduct}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Product}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Unite}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  {
                    el.Depot.map(el => (
                      <>
                        <p className="text-sm font-bold text-navy-700">{el}</p>
                        
                      </>
                    ))
                  }
                  <p>-</p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  {
                    el.Dispo.map(el => (
                      <>
                        <p className="text-sm font-bold text-navy-700">{el}</p>
                      </>
                    ))
                  }
                  <p className="text-lg font-bold">
                    {
                      el.Dispo.reduce(
                        (
                          previousValue, currentValue) =>
                          previousValue + currentValue, 0
                        )
                    }
                  </p>

                </td>
              </tr>
            ))
          }
          </tbody>
          :
          <tbody>
          {
            listOfProducts.map(el => (
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.CodeProduct}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Product}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Unite}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Depot}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-sm font-bold text-navy-700">
                    {el.Dispo}
                  </p>
                </td>
              </tr>
            ))
          }
          </tbody>
        }
        </table>
      }

      <div className="mt-5">
        <Button
          onClick={() => handleCloseReleveOp()}
          className="linear rounded-[10px] bg-gray-500 font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-gray-900 dark:bg-gray-400 dark:hover:bg-gray-300 dark:active:opacity-90"
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}

export default ProductInventaire;

import React from "react";

// Admin Imports
// import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Pages Imports
import Dashboard from "views/pages/Dashboard";
import OperatorManagement from "views/pages/Operator_management";
import Chronologie from "views/pages/Chronologie";
import CustomerList from "views/pages/Customer_list";
import ProductsList from "views/pages/Products_list";
import InventoryManagement from "views/pages/Inventory_management";
import Invoces from "views/pages/Invoces";
import Recouvry from "views/pages/Recouvry"
import Depot from "views/pages/Depot";
import SellOperations from "views/pages/Sell_operations";
import OtherOperations from "views/pages/Other_operations";
import StockTransfert from "views/pages/Stock_transfer";
import DepotsMovemments from "views/pages/Depots_movemments";
import ProductsMovements from "views/pages/Products_movemments";
import DailySellsList from "views/pages/Daily_sells_list";
import DailySellsJrl from "views/pages/Daily_sells_jrl";
import EncaissementsStatus from "views/pages/Encaissements_status";
import StockEnter from "views/pages/Stock_enter";
import EssayManagements from "views/pages/Essay_managements";
import CompanyFees from "views/pages/CompanyFees";
import CompanyFeesDg from "views/pages/Company_fees_dg";
import CompanyFeesValidation from "views/pages/Company_fees_validation"
import RecovryValidation from "views/pages/RecouvryValidation";
import RoleManagement from "views/pages/RoleManagement"
import Categories from "views/pages/Categories";

// Icon Imports
import {
  MdHome,
  MdOutlineSecurity,
  MdInventory,
  MdCategory,
  MdOutlineDisplaySettings,
  MdWarehouse,
} from "react-icons/md";

import { FaUsersGear, FaFileInvoice, FaSackDollar } from "react-icons/fa6";
import { TbTimelineEventText, TbShoppingCartCog, TbTransferIn, TbShoppingCartUp  } from "react-icons/tb";
import { FaUsers, FaMoneyCheckAlt, FaWarehouse, FaClipboardList } from "react-icons/fa";
import { GrMoney, GrStatusInfo  } from "react-icons/gr";
import { BsFillHouseUpFill } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { ImLeaf } from "react-icons/im";

const routes = [
  {
    name: "SignIn",
    layout: "/auth",
    pathName: "sign-in",
    path: "sign-in",
    icon: <MdHome className="h-6 w-6" />,
    component: <SignIn />,
    showRoute: true,
    id: "65dcad403cc26f3f505b65ca"
  },
  {
    name: "Tableau de bord",
    layout: "/admin",
    pathName: "default",
    path: "default/:userID",
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
    showRoute: false,
    id: "65d913d78c1ce339f0935375"
  },
  {
    name: "Gestion des opérateurs",
    layout: "/admin",
    pathName: "operators",
    path: "operators/:userID",
    icon: <FaUsersGear className="h-6 w-6" />,
    component: <OperatorManagement />,
    showRoute: true,
    id: "66239b5b80f4061cc8139d83"
  },
  {
    name: "Gestion des roles", // not added
    layout: "/admin",
    pathName: "roles",
    path: "roles/:userID",
    icon: <MdOutlineSecurity className="h-6 w-6" />,
    component: <RoleManagement />,
    showRoute: true,
    id: "66239a751662aec22901caec"
  },
  {
    name: "Chronologie",
    layout: "/admin",
    pathName: "Chronologie",
    path: "Chronologie/:userID",
    icon: <TbTimelineEventText className="h-6 w-6" />,
    component: <Chronologie />,
    showRoute: true,
    id: "65dcad583cc26f3f505b65cb"
  },
  {
    name: "Liste des clients",
    layout: "/admin",
    pathName: "CustomerList",
    path: "CustomerList/:userID",
    icon: <FaUsers className="h-6 w-6" />,
    component: <CustomerList />,
    showRoute: true,
    id: "65d913e78c1ce339f0935376"
  },
  {
    name: "Liste des produits",
    layout: "/admin",
    pathName: "ProductsList",
    path: "ProductsList/:userID",
    icon: <TbShoppingCartCog className="h-6 w-6" />,
    component: <ProductsList />,
    showRoute: true,
    id: "65dcad643cc26f3f505b65cc"
  },
  {
    name: "Gestion de stock",
    layout: "/admin",
    pathName: "InventoryManagement",
    path: "InventoryManagement/:userID",
    icon: <MdInventory className="h-6 w-6" />,
    component: <InventoryManagement />,
    showRoute: true,
    id: "65dcad743cc26f3f505b65cd"
  },
  {
    name: "Facturation",
    layout: "/admin",
    pathName: "Invoces",
    path: "Invoces/:userID",
    icon: <FaFileInvoice className="h-6 w-6" />,
    component: <Invoces />,
    showRoute: true,
    id: "65dcad7f3cc26f3f505b65ce"
  },
  {
    name: "Recouvrements", // not added
    layout: "/admin",
    pathName: "RecovryValidation",
    path: "RecovryValidation/:userID",
    icon: <FaMoneyCheckAlt className="h-6 w-6" />,
    component: <RecovryValidation />,
    showRoute: true,
    id: "66239addf4dab7a77df65e30"
  },
  {
    name: "Echéancier",
    layout: "/admin",
    pathName: "Recouvry",
    path: "Recouvry/:userID",
    icon: <GrMoney className="h-6 w-6" />,
    component: <Recouvry />,
    showRoute: true,
    id: "65dcad873cc26f3f505b65cf"
  },
  {
    name: "Dépôts",
    layout: "/admin",
    pathName: "Depot",
    path: "Depot/:userID",
    icon: <FaWarehouse className="h-6 w-6" />,
    component: <Depot />,
    showRoute: true,
    id: "65dcad8f3cc26f3f505b65d0"
  },
  {
    name: "Catégories",
    layout: "/admin",
    pathName: "Categories",
    path: "Categories/:userID",
    icon: <MdCategory className="h-6 w-6" />,
    component: <Categories />,
    showRoute: true,
    id: "65dcad8f3cc26f3f505b65d1"
  },
  {
    name: "Opérations (ventes)",
    layout: "/admin",
    pathName: "SellOperations",
    path: "SellOperations/:userID",
    icon: <FaSackDollar className="h-6 w-6" />,
    component: <SellOperations />,
    showRoute: true,
    id: "65dcad9c3cc26f3f505b65d1"
  },
  {
    name: "Opérations",
    layout: "/admin",
    pathName: "OtherOperations",
    path: "OtherOperations/:userID",
    icon: <MdOutlineDisplaySettings className="h-6 w-6" />,
    component: <OtherOperations />,
    showRoute: true,
    id: "65dcada73cc26f3f505b65d2"
  },
  {
    name: "Transfert de stock",
    layout: "/admin",
    pathName: "StockTransfert",
    path: "StockTransfert/:userID",
    icon: <TbTransferIn className="h-6 w-6" />,
    component: <StockTransfert />,
    showRoute: true,
    id: "65dcadb33cc26f3f505b65d3"
  },
  {
    name: "Mouvements des dépots",
    layout: "/admin",
    pathName: "DepotsMovemments",
    path: "DepotsMovemments/:userID",
    icon: <BsFillHouseUpFill className="h-6 w-6" />,
    component: <DepotsMovemments />,
    showRoute: true,
    id: "65dcadbe3cc26f3f505b65d4"
  },
  {
    name: "Mouvements des produits",
    layout: "/admin",
    pathName: "ProductsMovements",
    path: "ProductsMovements/:userID",
    icon: <TbShoppingCartUp className="h-6 w-6" />,
    component: <ProductsMovements />,
    showRoute: true,
    id: "65dcadca3cc26f3f505b65d5"
  },
  {
    name: "Liste des ventes journalières",
    layout: "/admin",
    pathName: "DailySellsList",
    path: "DailySellsList/:userID",
    icon: <FaClipboardList className="h-6 w-6" />,
    component: <DailySellsList />,
    showRoute: true,
    id: "65dcadd83cc26f3f505b65d6"
  },
  {
    name: "Journal comptable des ventes",
    layout: "/admin",
    pathName: "DailySellsJrl",
    path: "DailySellsJrl/:userID",
    icon: <FaClipboardList className="h-6 w-6" />,
    component: <DailySellsJrl />,
    showRoute: true,
    id: "65dcade63cc26f3f505b65d7"
  },
  {
    name: "Etat des encaissements",
    layout: "/admin",
    pathName: "EncaissementsStatus",
    path: "EncaissementsStatus/:userID",
    icon: <GrStatusInfo className="h-6 w-6" />,
    component: <EncaissementsStatus />,
    showRoute: true,
    id: "65dcadf13cc26f3f505b65d8"
  },
  {
    name: "Entrée de stock",
    layout: "/admin",
    pathName: "StockEnter",
    path: "StockEnter/:userID",
    icon: <MdWarehouse className="h-6 w-6" />,
    component: <StockEnter />,
    showRoute: true,
    id: "65dcadfb3cc26f3f505b65d9"
  },
  {
    name: "Frais de société",
    layout: "/admin",
    pathName: "CompanyFees",
    path: "CompanyFees/:userID",
    icon: <IoBusiness className="h-6 w-6" />,
    component: <CompanyFees />,
    showRoute: true,
    id: "65dcae083cc26f3f505b65da"
  },
  {
    name: "Frais de société DG",
    layout: "/admin",
    pathName: "CompanyFeeDg",
    path: "CompanyFeeDg/:userID",
    icon: <IoBusiness className="h-6 w-6" />,
    component: <CompanyFeesDg />,
    showRoute: true,
    id: "65dcae1d3cc26f3f505b65dc"
  },
  {
    name: "Validation frais de société",
    layout: "/admin",
    pathName: "CompanyFeeValidation",
    path: "CompanyFeeValidation/:userID",
    icon: <IoBusiness className="h-6 w-6" />,
    component: <CompanyFeesValidation />,
    showRoute: true,
    id: "6638c37e6c5bb01cd09fdb92"
  },
  {
    name: "Gestion des Essais",
    layout: "/admin",
    pathName: "EssayManagements",
    path: "EssayManagements/:userID",
    icon: <ImLeaf className="h-6 w-6" />,
    component: <EssayManagements />,
    showRoute: true,
    id: "65dcae113cc26f3f505b65db"
  },

  // SellOperations OtherOperations StockTransfert StockEnter CompanyFees CompanyFeesDg

  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },

  

  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;

import { Alert } from '@material-tailwind/react';
import React, { useEffect, useState } from 'react'

function RecouvryDates({ selectedReportId, selectedRecovryInfo, selectedRecovryType }) {
    const [recovryDates, setRecovryDates] = useState()

    useEffect(() => {
        getInitialData();
    }, [selectedReportId]);

    const getInitialData = () => {
        try {
          setRecovryDates(selectedRecovryInfo?.DateList)
        }
        catch(err) {
            console.log(err)
        }
    }

  return (
    <div>
      {
        recovryDates &&
        <div className='flex flex-col justify-center items-center'>
          {
            recovryDates.map(el => (
              <Alert variant="gradient" className='w-1/4 mb-2'>
                <span>{el}</span>
              </Alert>
            ))
          }
        </div>
      }

    </div>
  )
}

export default RecouvryDates

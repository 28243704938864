import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";

import Swal from "sweetalert2";

import { signInApi, signInOperatorApi } from "api/superAdmin";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { setCookie } from "./../../utils/cookies";
import { userAccess } from "api/superAdmin";

export default function SignIn() {
  const navigate = useNavigate();

  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserForm({ ...userForm, [name]: value });
  };

  const handleSignInM = async () => {
    try {
      const userIsAdmin = await userAccess(userForm.email)
      console.log("userIsAdmin.data", userIsAdmin.data)

      if(userIsAdmin.data) {
        const response = await signInApi(userForm.email, userForm.password);

        await setCookie("T6hVzR7c0g3maM3F", response.token.content)
        await setCookie("02hfCmUqpk66w4uG", response.token.iv)
        await setCookie("3F2Mb2pJ1YRro5Z4", response.userInfo._id)
  
        await setCookie("lv9u765qzk1gr4fn1e", "TRUE")
        window.location.reload()

        return
      }
      else if(!userIsAdmin.data) {
        const response = await signInOperatorApi(userForm.email, userForm.password);

        await setCookie("T6hVzR7c0g3maM3F", response.token.content)
        await setCookie("02hfCmUqpk66w4uG", response.token.iv)
        await setCookie("3F2Mb2pJ1YRro5Z4", response.userInfo._id)

        await setCookie("lv9u765qzk1gr4fn1e", "FALSE")
        window.location.reload()

        return
      }
    } catch (error) {
      console.log("error =>", error)
      Swal.fire({
        title: "Error!",
        text: error?.response?.data?.error,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          AUTHENTIFICATION DV
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          ACCEDEZ A VOTRE ARDUNIFER
        </p>
        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="mail@simmmple.com"
          id="email"
          type="text"
          value={userForm.email}
          name="email"
          onChange={handleInputChange}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Mot de pass*"
          placeholder=""
          id="password"
          type="password"
          value={userForm.password}
          name="password"
          onChange={handleInputChange}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          {/* <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div> */}
          {/* <a
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            href=" "
          >
            Forgot Password?
          </a> */}
        </div>
        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleSignInM}
        >
          Sign In
        </button>
        {/* <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <a
            href=" "
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </a>
        </div> */}
      </div>
    </div>
  );
}

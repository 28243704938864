import React, { useEffect, useState } from "react";
import { Button } from "@material-tailwind/react";

import { BsPrinterFill } from "react-icons/bs";
import { getInvoceDataApi } from "api/clients";
import { getInvoceYearsApi } from "api/clients";
import { getOneClientApi } from "api/clients";
import { getInvocePdfDataApi } from "api/clients";
import API_URL from "config";
import { relevePrevYearsApi } from "api/clients";

function ClientInvoce({ closePopup, clientID }) {
  const [invoceData, setInvoceData] = useState([]);
  const [invoceYears, setInvoceYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getUTCFullYear());

  useEffect(() => {
    getInitialData(selectedYear);
  }, [clientID]);

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value)
    getInitialData(e.target.value);
  };

  const handleCloseReleveOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handlePrintClientList = async () => {
    try {
      const clientDetails = await getOneClientApi(clientID);
      const pdfInvoce = await getInvocePdfDataApi(clientDetails.ClientCode, selectedYear)
      window.open(`${pdfInvoce}`, "_blank");
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialData = async (selectedYear) => {
    try {
      // const currentYear = new Date().getUTCFullYear()
      // setSelectedYear(currentYear)

      const clientDetails = await getOneClientApi(clientID);

      await relevePrevYearsApi(clientDetails.ClientCode, new Date().getFullYear())
      await relevePrevYearsApi(clientDetails.ClientCode, new Date().getFullYear()-1)

      const invoceData = await getInvoceDataApi(
        clientDetails.ClientCode,
        selectedYear
      );
      const invoceYears = await getInvoceYearsApi(clientDetails.ClientCode);

      setInvoceData(invoceData);
      setInvoceYears(invoceYears);

      console.log("invoceData", invoceData);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <select
            label="select Version"
            className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            defaultValue={selectedYear}
            onChange={handleYearChange}
          >
            {invoceYears.map((el) => (
              <option value={el}>{el}</option>
            ))}
          </select>
        </div>
        <div>
          <BsPrinterFill
            size={50}
            className="cursor-pointer"
            onClick={handlePrintClientList}
          />
        </div>
      </div>

      {invoceData?.releveData ? (
        <div style={{ height: "300px", overflowY: "scroll" }}>
          <table className="w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Date</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Jrl</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Libellé</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Débit</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Crédit</p>
                </th>
                <th className="border-b border-gray-200 pr-28 pb-[10px] pt-3 pb-3 text-start dark:!border-navy-700">
                  <p className="text-sm tracking-wide text-gray-600">Solde</p>
                </th>
              </tr>
            </thead>

            <tbody>
              {invoceData?.releveData.map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{el.Date}</p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{el.jtl}</p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    {
                      el.Type == "Effet" || el.Type == "Cheque" || el.Type == "Effet" || el.Type == "Effet" ? <p className="text-sm font-bold text-navy-700">{el.Type} <br /> N°{el.Number} <br /> {el.DateEcheance}</p>
                      : el.Type == "Espece" || el.Type == "Virement" || el.Type == "Cheque_facture" ? <p className="text-sm font-bold text-navy-700">{el.Type} <br /> {el.Nfacture}</p>
                      : el.jtl == "VT" || el.jtl == "AV" ? <p className="text-sm font-bold text-navy-700"> {el.Type} <br /> #{el.FactureNumber}</p>
                      : <p className="text-sm font-bold text-navy-700"></p>
                    }
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{parseFloat(el.Debit).toFixed(2)}</p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{parseFloat(el.Credit).toFixed(2)}</p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{parseFloat(el.Solde).toFixed(2)}</p>
                  </td>
                </tr>
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500"></p>
                </td>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500"></p>
                </td>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500"></p>
                </td>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500">{invoceData.sumOfReleve.D}</p>
                </td>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500">{invoceData.sumOfReleve.C}</p>
                </td>
                <td className="pt-[14px] pb-[18px]">
                  <p className="text-lg font-bold text-gray-500"></p>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      ) : (
        <></>
      )}

      <div className="mt-5">
        <Button
          onClick={() => handleCloseReleveOp()}
          className="linear rounded-[10px] bg-gray-500 font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-gray-900 dark:bg-gray-400 dark:hover:bg-gray-300 dark:active:opacity-90"
        >
          Fermer
        </Button>
      </div>
    </div>
  );
}

export default ClientInvoce;

// import { columnsDataColumns } from "./variables/columnsData";
import ProductsTable from "./components/Products_table";
import GlobaleTable from "./components/Generale_table";
import TCPTable from "./components/Tcps_table";

import { getCookie } from "utils/cookies";
import { getOneOperatorApi } from "api/operator";
import { useEffect, useState } from "react";
import { Card } from "@material-tailwind/react";

const Dashboard = () => {
  const [showDashboardData, setShowDashboardData] = useState(false)

  useEffect(() => {
    getInitialData()
  }, [])

  const getInitialData = async () => {
    try {
      const id = getCookie("3F2Mb2pJ1YRro5Z4")
      const adminRole = getCookie("lv9u765qzk1gr4fn1e")

      if(adminRole === "FALSE") {
        const response = await getOneOperatorApi(id)
        const dashboardAccess = response.userFromRole.access.map(el => el.path)

        if(dashboardAccess.includes("default")) {
          setShowDashboardData(true)
        }
      }
      else if(adminRole === "TRUE") {
        setShowDashboardData(true)
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      
    }
  }

  return (
    <div>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {
            showDashboardData ? <ProductsTable /> : <></>
          }
        </div>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {
            showDashboardData ? <TCPTable /> : <></>
          }
        </div>
        <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
          {
            showDashboardData ? <GlobaleTable /> : <></>
          }
        </div>
    </div>
  );
};

export default Dashboard;

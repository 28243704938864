import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getAllOpReportsListApi = async(MP, TCP, OP, currentYear, RBC, RBL, RF, page) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/getAllOpRapportsList/${id}/${iv}?MP=${MP}&TCP=${TCP}&OP=${OP}&currentYear=${currentYear}&RBC=${RBC}&RBL=${RBL}&RF=${RF}&limit=10&page=${page}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}


import React, { useEffect, useState } from "react";
import Card from "components/card";

import { tcpTableApi, tcpTableXcelApi } from "api/dashboard";
import { Combobox } from "@headlessui/react";
import { getAllProductsNameApi } from "api/filtersData";
import { Spinner } from "@material-tailwind/react";
import API_URL from "config";

const TCPTable = () => {
  const [comboBoxData, setComboBoxData] = useState([]);
  const [selectedComboBoxData, setSelectedComboBoxData] = useState("");
  const [query, setQuery] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const filteredComboBoxData =
    query === ""
      ? comboBoxData
      : comboBoxData.filter((value) =>
          value
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const getInitialData = () => {
    try {
      setIsLoading(true);

      if (fromDate && toDate) {
        tcpTableApi(
          selectedComboBoxData,
          fromDate,
          toDate
        )
        .then((tcpTableRes) => {
          setTableData(tcpTableRes);
        })
        .catch (err => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false);
        })
      } else {
        tcpTableApi(selectedComboBoxData, null, null)
        .then((tcpTableRes) => {
          setTableData(tcpTableRes);
        })
        .catch (err => {
          console.log(err)
        })
        .finally(() => {
          setIsLoading(false);
        })
      }

      getAllProductsNameApi()
      .then(AllProductsName => {
        setComboBoxData(AllProductsName);
        setComboBoxData(["Tout", ...AllProductsName]);
      })
    } catch (err) {
      console.log(err);
    }
  };

  const handleFilterData = () => {
    try {
      getInitialData();
    }
    catch (err) {
      
    }
  }

  const handleDownloadXcel = async () => {
    try {
      setIsLoadingXcel(true);
      if (fromDate && toDate) {
        const productXcelTableRes = await tcpTableXcelApi(
          selectedComboBoxData,
          fromDate,
          toDate
        );
        window.open(`${API_URL}/${productXcelTableRes}`, "_blank");
      } else {
        const productXcelTableRes = await tcpTableXcelApi(
          selectedComboBoxData,
          null,
          null
        );
        window.open(`${API_URL}/${productXcelTableRes}`, "_blank");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  // Format number with space as thousand separators
  const formatNumber = (value) => {
    return new Intl.NumberFormat('en-EN', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  useEffect(() => {
    getInitialData();
  }, []); // selectedComboBoxData, fromDate, toDate

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div>
      ) : (
        <Card extra={"w-full pb-10 p-4 h-full"}>
          <header className="relative flex items-center justify-between">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              TABLE TCP
            </div>
            {isLoadingXcel ? (
              <div className="flex items-center justify-center">
                <Spinner className="h-12 w-12" />
              </div>
            ) : (
              <button
                href=""
                className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={handleDownloadXcel}
              >
                Télécharger excel
              </button>
            )}
          </header>

          <div className="mt-5 grid grid-cols-2 gap-5">
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par date
              </small>
              <div>
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={fromDate}
                  onChange={(event) => setFromDate(event.target.value)}
                />
                <input
                  type="date"
                  id=""
                  placeholder=""
                  className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                  value={toDate}
                  onChange={(event) => setToDate(event.target.value)}
                />
              </div>
            </div>
            <div>
              <small className="text-sm font-medium text-gray-600 dark:text-white">
                Filtrer par produit
              </small>
              <div>
                <Combobox
                  value={selectedComboBoxData}
                  onChange={setSelectedComboBoxData}
                >
                  <Combobox.Input
                    onChange={(event) => setQuery(event.target.value)}
                    // displayValue={(value) => value.name}
                    className="relative mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none"
                  />
                  <Combobox.Options className="absolute right-0 h-32 w-1/2 overflow-y-scroll bg-white">
                    {filteredComboBoxData?.map((value, index) => (
                      <Combobox.Option
                        key={index}
                        value={value}
                        className="cursor-pointer rounded-xl p-2 hover:bg-gray-100"
                      >
                        {value}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox>
              </div>
            </div>

            <button
                href=""
                className="linear rounded-[10px] bg-green-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
                onClick={handleFilterData}
              >
                Filtrer
              </button>

          </div>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">TCP</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600 text-right">
                      Quantité
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600 text-right">
                      Montant HT
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData?.data ? (
                  Object.entries(tableData?.data).map(([key, value]) => {
                    const Quantity = tableData?.data[key][tableData?.data[key].length-1].sumOfQuantity
                    const Price = tableData?.data[key][tableData?.data[key].length-1].sumOfAmount

                    return (
                      <tr>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {key}
                          </p>
                        </td>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                            {formatNumber(Quantity.toFixed(2))}
                          </p>
                        </td>
                        <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                          <p className="text-sm font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                            {formatNumber(Price.toFixed(2))}
                          </p>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <></>
                )}

                <tr className="bg-gray-100 dark:bg-brand-400">
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white"></p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                      {formatNumber(tableData?.sumOfQuantity?.toFixed(2))}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-[22px] font-bold text-navy-700 dark:text-white text-right mr-1 font-mono">
                      {formatNumber(tableData?.sumOfAmount?.toFixed(2))}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Card>
      )}
    </>
  );
};

export default TCPTable;

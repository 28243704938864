import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  CardFooter,
} from "@material-tailwind/react";

import { ToastContainer, toast } from "react-toastify";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ReportDetails from "./ReportDetails";
import EssayEditeForm from "./EssayEditeForm";
import EssayCommentForm from "./EssayCommentForm";

import { updateOneEssayStatusReportsApi } from "api/essay";

function EssayCard({ tableData, reloadData, accessList, showToast, isAdmin }) {
  const [openReportDetails, setOpenReportDetails] = useState(false);
  const [openReportEditeForm, setOpenReportEditeForm] = useState(false);
  const [openReportCommentForm, setOpenReportCommentForm] = useState(false);
  const [reportDetailsData, setReportDetailsData] = useState([]);

  useEffect(() => {
    getTableData();
  }, [tableData]);

  const getTableData = async () => {
    try {

    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenReportDetails = (data) => {
    setReportDetailsData(data)
    setOpenReportDetails(!openReportDetails);
  };

  const handleOpenEditeReportForm = (data) => {
    setReportDetailsData(data)
    setOpenReportEditeForm(!openReportEditeForm);
  };

  const handleOpenEditeReportCommentForm = (data) => {
    setReportDetailsData(data)
    setOpenReportCommentForm(!openReportCommentForm)
  }

  const handleCancelForm = () => {
    setOpenReportEditeForm(!openReportEditeForm);
  }

  const handleCancelCommentForm = () => {
    setOpenReportCommentForm(!openReportCommentForm)
  }

  const handleShowToast = (type, message) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      reloadData();
    }
    else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  }

  // const handleValidateReport = (id, status) => {
  //   try {
  //     // withReactContent(Swal)
  //     //   .fire({
  //     //     icon: "warning",
  //     //     title: "Voulez-vous vraiment valider cette opération ?",
  //     //     confirmButtonText: "Valider",
  //     //   })
  //     //   .then(async (result) => {
  //     //     if (result.isConfirmed) {
  //     //       try {
  //     //         await updateOneEssayStatusReportsApi(id, status)
  //     //         reloadData()
  //     //         Swal.fire("Validée", "", "success")
  //     //       } catch (err) {
  //     //         Swal.fire(err?.response?.data?.error, "", "error");
  //     //       }
  //     //     }
  //     //   });
  //   }
  //   catch (err) {
  //     console.log(err);
  //   }
  // }

  const handleCancelReport = (id, status) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment supprimer cette opération ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await updateOneEssayStatusReportsApi(id, status, "")
              reloadData()
              Swal.fire("Annulée", "", "success")
            } catch (err) {
              Swal.fire(err?.response?.data?.error, "", "error");
            }
          }
        });
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <ToastContainer />
      <Dialog
        size="xl"
        open={openReportDetails}
        handler={handleOpenReportDetails}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <ReportDetails typeOf="MOROCCO" reportDetailsData={reportDetailsData} />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={openReportEditeForm}
        handler={handleOpenEditeReportForm}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <EssayEditeForm
            typeOf="MOROCCO"
            reportDetailsData={reportDetailsData}
            handleCancelForm={handleCancelForm}
            showToast={handleShowToast}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={openReportCommentForm}
        handler={handleOpenEditeReportCommentForm}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <EssayCommentForm reportDetailsData={reportDetailsData} handleCancelCommentForm={handleCancelCommentForm} showToast={handleShowToast} />
        </DialogBody>
      </Dialog>

      {tableData.map((el) => (
        <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
          <CardBody onClick={() => handleOpenReportDetails(el)}>
            <Typography
              variant="h5"
              color="blue-gray"
              className="mb-2 dark:text-white"
            >
              {el?.Rapport?.InfoVisiteEssay?.Variete}
            </Typography>
            <Typography
              variant="h6"
              color="blue-gray"
              className="dark:text-white"
            >
              {el?.Rapport?.InfoVisiteEssay?.ClientName}
            </Typography>
            <Typography>{el?.Rapport?.InfoVisiteEssay?.Adress}</Typography>
            <Typography>Visite essai / {el?.for?.Name}</Typography>
            <Typography>Modifier le: {el.updatedAt.split('T')[0].split('-').reverse().join('-')} - {el.updatedAt.split('T')[1].slice(0, 5)}</Typography>
          </CardBody>
          {
            el.EtatFacturation === 'V' ?
            <CardFooter className="flex pt-0">
              <Button
                variant="gradient"
                color="#8bc34a"
                className="mr-1"
                disabled
              >
                <span>Terminée</span>
              </Button>
            </CardFooter>
          :
          el.EtatFacturation === 'A' ?
            <CardFooter className="flex pt-0">
              <Button
                variant="gradient"
                color="#cf503f"
                className="mr-1"
                disabled
              >
                <span>Supprimée</span>
              </Button>
            </CardFooter>
          :
            <CardFooter className="flex pt-0">
            <Button
              variant="gradient"
              color="#8bc34a"
              onClick={() => handleOpenEditeReportCommentForm(el)}
              className="mr-1"
            >
              <span>Terminer</span>
            </Button>

            <Button
              variant="gradient"
              color="orange"
              onClick={() => handleOpenEditeReportForm(el)}
              className="mr-1"
            >
              <span>Modifier</span>
            </Button>

            <Button
              variant="gradient"
              color="#cf503f"
              onClick={() => handleCancelReport(el._id, "A")}
              className="mr-1"
            >
              <span>Supprimer</span>
            </Button>
            </CardFooter>
          }
        </Card>
      ))}
    </>
  );
}

export default EssayCard;

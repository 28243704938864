import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewOperatorApi = async(formObjectData, imageProfile, Signature) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();
        formData.append('userFromRole', formObjectData?.userFromRole || "");
        formData.append('Name', formObjectData?.Name || "");
        formData.append('Phone', formObjectData?.Phone || "");
        formData.append('Email', formObjectData?.Email || "");
        formData.append('Cin', formObjectData?.Cin || "");
        formData.append('NameCode', formObjectData?.NameCode || "");
        formData.append('UserVehicul', formObjectData?.UserVehicul || "");
        formData.append('Password', formObjectData?.Password || "");
        formData.append('CPassword', formObjectData?.CPassword || "");

        if(formObjectData?.Zone)
            formData.append('Zone', formObjectData?.Zone || "");

        if(imageProfile)
            formData.append('imageProfile', imageProfile);

        if(Signature)
            formData.append('Signature', Signature);

        const res = await axios.post(`${API_URL}/api/V2/operators/addNewOperator/${id}/${iv}`, formData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateOperatorApi = async (formObjectData, imageProfile, Signature, opID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();
        formData.append('userFromRole', formObjectData?.userFromRole || "");
        formData.append('Name', formObjectData?.Name || "");
        formData.append('Phone', formObjectData?.Phone || "");
        formData.append('Email', formObjectData?.Email || "");
        formData.append('Cin', formObjectData?.Cin || "");
        formData.append('NameCode', formObjectData?.NameCode || "");
        formData.append('UserVehicul', formObjectData?.UserVehicul || "");

        if(formObjectData?.Zone)
            formData.append('Zone', formObjectData?.Zone || "");

        if(imageProfile)
            formData.append('imageProfile', imageProfile);

        if(Signature)
            formData.append('Signature', Signature);

        const res = await axios.put(`${API_URL}/api/V2/operators/updateOperator/${id}/${iv}/${opID}`, formData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllOperatorsApi = async () => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/operators/getAllOperators/${id}/${iv}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneOperatorApi = async (operatorID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/operators/getOneOperator/${id}/${iv}/${operatorID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteOperatorApi = async (operatorID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/operators/deleteOneOperator/${id}/${iv}/${operatorID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateOperatorPasswordApi = async (operatorID, newPassword, confirmPassword) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }

        const updatedObject = {
            newPassword: newPassword,
            confirmPassword: confirmPassword
        }

        const res = await axios.put(`${API_URL}/api/V2/operators/updatePassword/${id}/${iv}/${operatorID}`, updatedObject, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}


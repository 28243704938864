import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const pdfBaGeneratorApi = async(reportId, reportCategory, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/BAreport/${id}/${iv}/${token}/${reportId}/${reportCategory}/BA?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfBcGeneratorApi = async(reportId, reportCategory, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/BCreport/${id}/${iv}/${token}/${reportId}/${reportCategory}/BC?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfBlGeneratorApi = async(reportId, reportCity, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/BLreport/${id}/${iv}/${token}/${reportId}/${reportCity}/BL?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfBrGeneratorApi = async(reportId, reportCity, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/BRreport/${id}/${iv}/${token}/${reportId}/${reportCity}/BL?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfInvoceGeneratorApi = async(reportId, reportCategory, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/Freport/${id}/${iv}/${token}/${reportId}/${reportCategory}/Facture?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfAvoirGeneratorApi = async(reportId, reportCategory, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/AvoirReport/${id}/${iv}/${token}/${reportId}/${reportCategory}/Avoir?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfRecouvryGeneratorApi = async (reportId, reportCategory) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/RecouvryReport/${id}/${iv}/${token}/${reportId}/${reportCategory}/Recouvrement`
    }
    catch (err) {
        throw err
    }
}

export const pdfStockGeneratorApi = async (reportId, fromCity, toCity) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/StockTransfertReport/${id}/${iv}/${token}/${reportId}/${fromCity}/${toCity}/transferdestock`
    }
    catch (err) {
        throw err
    }
}

export const pdfCompanyFeeGeneratorApi = async (urlQuery) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/companyFees/${id}/${iv}/${token}/fraisdesociété?typeOfOp=${urlQuery.typeOfOp}&isAproved=${urlQuery.isAproved}&isJustify=${urlQuery.isJustify}&responsable=${urlQuery.responsable}&selectedFromDate=${urlQuery.selectedFromDate}&selectedToDate=${urlQuery.selectedToDate}`
    }
    catch (err) {
        throw err
    }
}

export const pdfOneCompanyFeeGeneratorApi = async (docID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/companyFees/${id}/${iv}/${token}/${docID}/fraisdesociété`
    }
    catch (err) {
        throw err
    }
}

export const pdfInvetaireGeneratorApi = async (urlQuery) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/products/productInvetaireListPdf?Product=${urlQuery.Product}&category=${urlQuery.Categorie}&Depot=${urlQuery.Depot}&categoryID=`
    }
    catch (err) {
        throw err
    }
}

export const pdfDevisGeneratorApi = async(reportId, reportCategory, indexOf = 0) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/devi/${id}/${iv}/${token}/${reportId}/${reportCategory}/Devis?indexOf=${indexOf}`
    }
    catch (err) {
        throw err
    }
}

export const pdfVisiteEssayGeneratorApi = (reportId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        return `${API_URL}/api/V2/pdfGenerator/VEreport/${id}/${iv}/${token}/${reportId}/VisiteEssay`
    }
    catch (err) {
        throw err
    }
}

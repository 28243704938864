import React, { useEffect, useState } from "react";
import Card from "components/card";
import {
  Switch,
  Button,
  IconButton,
  Spinner,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";

import { IoEyeSharp } from "react-icons/io5";
import { HiPencilAlt } from "react-icons/hi";
import { FaTrash, FaFileDownload } from "react-icons/fa";

import { ToastContainer, toast } from "react-toastify";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ClientForm from "./Client_form";
import ClientInvoce from "./Client_invoce";

import { getAllClientsApi } from "api/clients";
import { getAllTcpsApi } from "api/filtersData";
import { updateClientStatusApi } from "api/clients";
import { deleteClientApi } from "api/clients";
import { getClientsXcelDataApi } from "api/clients";
import API_URL from "config";
import { getAllOperatorsApi } from "api/filtersData";

function ClientTable({ accessList }) {
  const [clienFormAction, setClienFormAction] = useState("show");

  const [showClientForm, setShowClientForm] = useState(false);
  const [showInvoceModale, setShowInvoceModale] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingXcel, setIsLoadingXcel] = useState(false);

  const [tableData, setTableData] = useState([]);

  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfClients, setNumberOfClients] = useState(0);

  const [tcpData, setTcpData] = useState([]);

  const [inputFilterValue, setInputFilterValue] = useState({
    ClientName: "",
    ClientICE: "",
    ClientCode: "",
    ClientUCodeFor: "",
  });
  const [emptyICE, setEmptyICE] = useState(false);

  const [selectedClientID, setSelectedClientID] = useState("");

  useEffect(() => {
    getAllTcpData();
    getInitialData(
      active,
      inputFilterValue.ClientCode,
      inputFilterValue.ClientName,
      inputFilterValue.ClientICE,
      inputFilterValue.ClientUCodeFor,
      emptyICE ? "true" : ""
    );
  }, [emptyICE]); // inputFilterValue

  const handleClientXcelDownload = async () => {
    try {
      setIsLoadingXcel(true);
      const response = await getClientsXcelDataApi(
        active,
        inputFilterValue.ClientCode,
        inputFilterValue.ClientName,
        inputFilterValue.ClientICE,
        inputFilterValue.ClientUCodeFor,
        emptyICE ? "true" : ""
      );

      await new Promise((resolve) => {
        setTimeout(() => {
          window.open(`${API_URL}/${response}`, "_blank");
          resolve();
        }, 1000);
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingXcel(false);
    }
  };

  const handleClientChangeStatus = async (status, clientID) => {
    try {
      await updateClientStatusApi(!status, clientID);

      toast.success(!status ? `Client activée` : `Client désactivée`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getInitialData(
        active,
        inputFilterValue.ClientCode,
        inputFilterValue.ClientName,
        inputFilterValue.ClientICE,
        inputFilterValue.ClientUCodeFor,
        emptyICE ? "true" : ""
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });
    // setCurrentPagination(1);
    // getInitialData(
    //   active,
    //   name == "ClientCode" ? value : inputFilterValue.ClientCode,
    //   name == "ClientName" ? value : inputFilterValue.ClientName,
    //   name == "ClientICE" ? value : inputFilterValue.ClientICE,
    //   name == "ClientUCodeFor" ? value : inputFilterValue.ClientUCodeFor,
    //   emptyICE ? "true" : ""
    // );
  };

  const handleEmptyICEChange = () => {
    setEmptyICE(!emptyICE);
  };

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getInitialData(
        index,
        inputFilterValue.ClientCode,
        inputFilterValue.ClientName,
        inputFilterValue.ClientICE,
        inputFilterValue.ClientUCodeFor,
        emptyICE ? "true" : ""
      );
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };

  const getAllTcpData = async () => {
    try {
      const tcpData = await getAllOperatorsApi();
      setTcpData(tcpData);
    } catch (err) {
      console.log(err);
    }
  };

  const getInitialData = async (
    page,
    ClientCode,
    ClientName,
    ClientICE,
    ClientUCodeFor,
    emptyICE
  ) => {
    try {
      setIsLoading(true);
      const clientData = await getAllClientsApi(
        page,
        ClientCode,
        ClientName,
        ClientICE,
        ClientUCodeFor,
        emptyICE
      );
      setTableData(clientData.data);
      setNumberOfPages(clientData.pagination.numberOfPages);
      setNumberOfPaginations(clientData.pagination.numberOfPaginations);
      setNumberOfClients(clientData.pagination.numberOfClients);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteClient = (clientID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await deleteClientApi(clientID);
            getInitialData(
              active,
              inputFilterValue.ClientCode,
              inputFilterValue.ClientName,
              inputFilterValue.ClientICE,
              inputFilterValue.ClientUCodeFor,
              emptyICE ? "true" : ""
            );
            Swal.fire("Supprimée", "", "success");
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClientFormModal = (action, id) => {
    try {
      setShowClientForm(!showClientForm);
      setClienFormAction(action);
      setSelectedClientID(id);
    } catch (err) {
      console.log(err);
    }
  };

  const handleInvoceModal = (id) => {
    try {
      setShowInvoceModale(!showInvoceModale);
      setSelectedClientID(id);
    } catch (err) {
      console.log(err);
    }
  };

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      setShowClientForm(!showClientForm);

      getInitialData(
        active,
        inputFilterValue.ClientCode,
        inputFilterValue.ClientName,
        inputFilterValue.ClientICE,
        inputFilterValue.ClientUCodeFor,
        emptyICE ? "true" : ""
      );

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFilterClientData = () => {
    try {
      getInitialData(
        active,
        inputFilterValue.ClientCode,
        inputFilterValue.ClientName,
        inputFilterValue.ClientICE,
        inputFilterValue.ClientUCodeFor,
        emptyICE ? "true" : ""
      );
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog size="xl" open={showClientForm} handler={handleClientFormModal}>
        <DialogHeader>
          <p className="w-full text-center">
            {clienFormAction === "add"
              ? "Nouveau client"
              : clienFormAction === "edite"
              ? "Modifier le client"
              : "Voir les details de client"}
          </p>
        </DialogHeader>
        <DialogBody>
          <ClientForm
            closePopup={handleClientFormModal}
            clienFormAction={clienFormAction}
            showToast={hadleShowToast}
            clientID={selectedClientID}
          />
        </DialogBody>
      </Dialog>

      <Dialog size="xl" open={showInvoceModale} handler={handleInvoceModal}>
        <DialogHeader>
          <p className="w-full text-center">Relevé</p>
        </DialogHeader>
        <DialogBody>
          <ClientInvoce
            closePopup={handleInvoceModal}
            clientID={selectedClientID}
          />
        </DialogBody>
      </Dialog>

      <header className="relative flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0">
          Clients
        </div>
        {
          accessList.Add &&
          <Button
          onClick={() => handleClientFormModal("add", null)}
          className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Nouveau client
          </Button>
        }

        {
          accessList.Read &&
          <>
            {isLoadingXcel ? (
              <div className="flex items-center justify-center">
                <Spinner className="h-12 w-12" />
              </div>
            ) : 
              <Button
                className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
                onClick={handleClientXcelDownload}
              >
                Télécharger excel
              </Button>
            }
          </>
        }
      </header>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-5">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par TCP :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ClientUCodeFor"
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {tcpData.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ClientName"
              value={inputFilterValue.ClientName}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par ICE / CIN :
            </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ClientICE"
              value={inputFilterValue.ClientICE}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer code client :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ClientCode"
              value={inputFilterValue.ClientCode}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Vide ICE/CIN :
          </small>
          <div className="mt-2">
            <Switch checked={emptyICE} onChange={handleEmptyICEChange} />
          </div>
        </div>
        {/* filter btn */}
        <div>
          <Button
            className="linear mb-5 rounded-[10px] bg-green-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-green-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
            onClick={handleFilterClientData}
          >
            Filtrer
          </Button>
        </div>
      </div>

      <div
        className="mt-8 overflow-x-scroll"
        style={{ height: "300px", overflowY: "scroll" }}
      >
        <table className="w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">TCP</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Client</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Code</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Téléphone</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">ICE/CIN</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Actions</p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Activer/Désactiver
                </p>
              </th>
              <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Relevé</p>
              </th>
            </tr>
          </thead>

          {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <Spinner className="h-12 w-12" />
            </div>
          ) : (
            <tbody>
              {tableData.map((el) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el?.ClientUCodeFor?.Name}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.Name}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.ClientCode}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.Phone}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {el.ICE}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex justify-around">
                        {
                          accessList.Read &&
                          <IoEyeSharp
                            size={20}
                            color="gray"
                            className="cursor-pointer"
                            onClick={() => handleClientFormModal("show", el._id)}
                          />
                        }

                        {
                          accessList.Update &&
                          <HiPencilAlt
                            size={20}
                            color="#8bc34a"
                            className="cursor-pointer"
                            onClick={() => handleClientFormModal("edite", el._id)}
                          />
                        }

                        {
                          accessList.Delete &&
                          <FaTrash
                            size={20}
                            color="#cf503f"
                            className="cursor-pointer"
                            onClick={() => handleDeleteClient(el._id)}
                          />
                        }
                      </div>
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex justify-center">
                        {
                          accessList.Update &&
                          <Switch
                            checked={el.IsActive}
                            onChange={() =>
                              handleClientChangeStatus(el.IsActive, el._id)
                            }
                          />
                        }
                      </div>
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      <div className="flex justify-center">
                        {
                          accessList.Read &&
                          <FaFileDownload
                            size={25}
                            className="cursor-pointer"
                            onClick={() => handleInvoceModal(el._id)}
                          />
                        }
                      </div>
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>

      {!isLoading && numberOfClients != 0 && (
        <div className="mt-4 flex items-center gap-4">
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={prev}
            disabled={currentPagination === 1}
          >
            <IoMdArrowDropleftCircle size={30} />
          </Button>
          <div className="flex items-center gap-2">
            {currentPagination !== numberOfPaginations
              ? // paginations row
                Array.from({
                  length: numberOfPages > 5 ? 5 : numberOfPages,
                }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })
              : // last pagination row
                Array.from({ length: numberOfPages % 5 }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })}
          </div>
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={next}
            disabled={
              currentPagination === numberOfPaginations ||
              numberOfPaginations === 0
            }
          >
            <IoMdArrowDroprightCircle size={30} />
          </Button>
        </div>
      )}
    </Card>
  );
}

export default ClientTable;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
} from "@material-tailwind/react";
import {
  getOneEssayReportsApi,
  deleteOneUpdatedEssayReportsApi,
} from "api/essay";
import { pdfVisiteEssayGeneratorApi } from "api/pdf";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import OneEssayEditeForm from "./OneEssayEditeForm";

import { HiPencilAlt } from "react-icons/hi";
import { MdDelete } from "react-icons/md";

function ReportDetails({ typeOf, reportDetailsData }) {
  const [openOneEssayEditeForm, setopenOneEssayEditeForm] = useState(false);
  const [selectedOneEssayEditeForm, setSelectedOneEssayEditeForm] =
    useState(null);
  const [oneReportData, setOneReportData] = useState([]);

  useEffect(() => {
    initialData(reportDetailsData._id);
  }, [reportDetailsData]);

  const initialData = async (reportID) => {
    try {
      const response = await getOneEssayReportsApi(reportID);
      setOneReportData(response);
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  const handleOpenOneEssayEditeForm = (selectedData) => {
    setSelectedOneEssayEditeForm(selectedData);
    setopenOneEssayEditeForm(!openOneEssayEditeForm);
  };

  const handleCancelData = () => {
    setopenOneEssayEditeForm(!openOneEssayEditeForm);
  };

  const handleDownloadReport = async () => {
    try {
      const response = await pdfVisiteEssayGeneratorApi(reportDetailsData._id);
      window.open(`${response}`, "_blank");
    } catch (err) {}
  };

  const handleDeleteOneEssayRowData = (EssaiID) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await deleteOneUpdatedEssayReportsApi(EssaiID._id);
              // console.log("EssaiID", EssaiID._id)
              // Swal.fire("Modifiée", "", "success");
              // showToast("success", "Modifiée")
            } catch (err) {
              console.log(err);
              Swal.fire("Cesser une lien correct", "", "error");
            } finally {
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Dialog
        size="xl"
        open={openOneEssayEditeForm}
        handler={handleOpenOneEssayEditeForm}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <OneEssayEditeForm
            typeOf="MOROCCO"
            selectedOneEssayEditeForm={selectedOneEssayEditeForm}
            handleCancelForm={handleCancelData}
          />
        </DialogBody>
      </Dialog>

      <div style={{ height: "60vh", overflowY: "scroll" }}>
        {oneReportData.map((el) => (
          <table className="mt-5 w-full">
            <thead>
              <tr>
                <th className="flex items-center justify-center border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                  <HiPencilAlt
                    size={20}
                    color="gray"
                    className="cursor-pointer"
                    onClick={() => handleOpenOneEssayEditeForm(el)}
                  />
                  <p className="mr-5 text-xs tracking-wide text-gray-600">
                    Date de création
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">TCP</p>
                </th>
                <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                  <p className="text-xs tracking-wide text-gray-600">
                    Description
                  </p>
                </th>
                <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-center text-sm font-bold text-navy-700">
                    {el.updatedCreatedDate
                      ? el.updatedCreatedDate
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")
                      : el.createdAt
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-")}{" "}
                    {el.updatedCreatedDate
                      ? el.updatedCreatedDate.split("T")[1].slice(0, 5)
                      : el.createdAt.split("T")[1].slice(0, 5)}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-center text-sm font-bold text-navy-700">
                    {el.fromUser.Name}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <p className="text-center text-sm font-bold text-navy-700">
                    {el.description}
                  </p>
                </td>
                <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                  <MdDelete
                    size={30}
                    color="#cf503f"
                    className="cursor-pointer"
                    onClick={() => handleDeleteOneEssayRowData(el)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      </div>

      <div className="">
        <Button
          variant="gradient"
          color="blue"
          onClick={() => handleDownloadReport()}
          className=""
        >
          <span>Telecharger PDF</span>
        </Button>
      </div>
    </div>
  );
}

export default ReportDetails;

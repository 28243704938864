import React from 'react'
import EncaissementsStatusTable from './components/Encaissements_status_table'

function EncaissementsStatus() {
  return (
    <div>
        <EncaissementsStatusTable />
    </div>
  )
}

export default EncaissementsStatus

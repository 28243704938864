import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const relevePrevYearsApi = async (userId, currentYear) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/relevePrevYearsV2/${id}/${iv}?userId=${userId}&currentYear=${currentYear}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllClientsApi = async(page, ClientCode, ClientName, ClientICE, ClientUCodeFor, emptyICE) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/codeClient/getAllClient/${id}/${iv}?limit=20&page=${page}&ClientCode=${ClientCode}&ClientName=${ClientName}&ClientICE=${ClientICE}&ClientUCodeFor=${ClientUCodeFor}&emptyICE=${emptyICE}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneClientApi = async(clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/codeClient/getOneClient/${id}/${iv}/${clientID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addNewClientApi = async (formObjectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/codeClient/addCodeClient/${id}/${iv}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateClientApi = async(formObjectData, clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/codeClient/updateCodeClient/${id}/${iv}/${clientID}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateClientStatusApi = async (status, clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.put(`${API_URL}/api/V2/codeClient/updateClientStatus/${id}/${iv}/${clientID}`, {IsActive: status}, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteClientApi = async(clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/codeClient/deleteOneClient/${id}/${iv}/${clientID}`, option);
        return res.data
    }
    catch (err) {
        return err
    }
}

export const getInvoceDataApi = async (userId, currentYear) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/releveV2/${id}/${iv}?userId=${userId}&currentYear=${currentYear}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getInvoceYearsApi = async (codeClient) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/releveYearsV2/${id}/${iv}?codeClient=${codeClient}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getInvocePdfDataApi = async (userId, currentYear) => {
    const token = getCookie("T6hVzR7c0g3maM3F")
    const iv = getCookie("02hfCmUqpk66w4uG")
    const id = getCookie("3F2Mb2pJ1YRro5Z4")

    // const option = {
    //     headers: {
    //         Authorization: `Bearer ${token}`
    //     }
    // }

    // const res = await axios.get(`${API_URL}/api/rapport/relevePDFV2/${id}/${iv}?userId=${userId}&currentYear=${currentYear}`, option);
    // return res.data

    return `${API_URL}/api/rapport/relevePDFV2/${id}/${iv}/${token}/relevée${currentYear}?userId=${userId}&currentYear=${currentYear}&calculatedFrom=2024`
}

export const getClientsXcelDataApi = async (page, ClientCode, ClientName, ClientICE, ClientUCodeFor, emptyICE) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/codeClient/getAllClientXcel/${id}/${iv}/clientListXcel?limit=20&page=${page}&ClientCode=${ClientCode}&ClientName=${ClientName}&ClientICE=${ClientICE}&ClientUCodeFor=${ClientUCodeFor}&emptyICE=${emptyICE}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const existingDataValuesApi = async (formObjectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/codeClient/existingDataValues/${id}/${iv}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const existingUpdateDataValuesApi = async (formObjectData, clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/codeClient/existingUpdateDataValues/${id}/${iv}/${clientID}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const addFormValidatorApi = async (formObjectData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/codeClient/codeClientAddFormValidator/${id}/${iv}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateFormValidatorApi = async (formObjectData, clientID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.post(`${API_URL}/api/V2/codeClient/codeClientUpdateFormValidator/${id}/${iv}/${clientID}`, formObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const updateStockApi = async(objectData, saveData) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const ObjectData = {
            Products: objectData
        }

        const res = await axios.post(`${API_URL}/api/V2/Stock/addStock/${id}/${iv}?saveData=${saveData}`, ObjectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getOneStockApi = async (stockID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/Stock/getOne/${id}/${iv}/${stockID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllStocks = async (TypeOf = "", Depot = "", TCP = "", EnterOrExit = "", FromYear = `${new Date().getFullYear()}-01-01`, ToYear = `${new Date().getFullYear()+1}-01-01`, Product = "", Category = "", page) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
        // const selectedTypeOf = TypeOf.length === 0
        // console.log("selectedTypeOf", selectedTypeOf)

        const res = await axios.get(`${API_URL}/api/V2/Stock/getAll/${id}/${iv}?Type=${TypeOf.length === 0 ? "" : JSON.stringify(TypeOf)}&Depot=${Depot}&TCP=${TCP}&EnterOrExit=${EnterOrExit}&FromYear=${FromYear}&ToYear=${ToYear}&Product=${Product}&Category=${Category}&limit=10&page=${page}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteStockTransfertApi = async (stockID) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/Stock/deleteStockTransfert/${id}/${iv}/${stockID}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}


import React, { useEffect, useState } from "react";
import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

import { HiPencilAlt, HiOutlineAdjustments } from "react-icons/hi";

import EditeRecouvryDate from "./EditeRecouvryDate";
import ListOfRecouvry from "./RecouvryDates";
import { getOneReportDetailsApi } from "api/gestionStock";

function ReportDetails({ selectedRecouvry, updateData, accessList }) {
  const [showEditeRecouvry, setShowEditeRecouvry] = useState(false);
  const [showListOfRecouvry, setShowListOfRecouvry] = useState(false);
  const [recovryDetails, setRecovryDetails] = useState([])
  const [selectedRecovry, setSelectedRecovry] = useState({})

  useEffect(() => {
    ReportDetails(selectedRecouvry)
  }, [selectedRecouvry])

  const ReportDetails = async (recouvry) => {
    try {
      const reportDetails = await getOneReportDetailsApi(recouvry.id);
      if(recouvry.type === "Cheque") {
        console.log("reportDetails", reportDetails.Rapport.recouvrement[recouvry.type].ChequeInfo[recouvry.index])
        setRecovryDetails(reportDetails.Rapport.recouvrement[recouvry.type].ChequeInfo[recouvry.index])
      }
      else if (recouvry.type === "Effet") {
        console.log("reportDetails", reportDetails.Rapport.recouvrement[recouvry.type].EffetInfo[recouvry.index])
        setRecovryDetails(reportDetails.Rapport.recouvrement[recouvry.type].EffetInfo[recouvry.index])
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleUpdateData = () => {
    updateData()
  }

  const handleEditeRecovryModale = (reportID, reportDetails, reportType) => {
    try {
      setShowEditeRecouvry(!showEditeRecouvry);
      setSelectedRecovry({
        reportID: reportID,
        reportDetails: reportDetails,
        reportType: reportType
      })
    } catch (err) {}
  };

  const handleListOfRecovryModale = (reportID, reportDetails, reportType) => {
    try {
      setShowListOfRecouvry(!showListOfRecouvry);
      setSelectedRecovry({
        reportID: reportID,
        reportDetails: reportDetails,
        reportType: reportType
      })
    } catch (err) {}
  };

  return (
    <div>
      <Dialog
        size="xl"
        open={showEditeRecouvry}
        handler={handleEditeRecovryModale}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <EditeRecouvryDate
            selectedReportId={selectedRecovry.reportID}
            selectedRecovryInfo={selectedRecovry.reportDetails}
            selectedRecovryType={selectedRecovry.reportType}
            updateData={handleUpdateData}
            closeModale={handleEditeRecovryModale}
          />
        </DialogBody>
      </Dialog>

      <Dialog
        size="xl"
        open={showListOfRecouvry}
        handler={handleListOfRecovryModale}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <ListOfRecouvry
            selectedReportId={selectedRecovry.reportID}
            selectedRecovryInfo={selectedRecovry.reportDetails}
            selectedRecovryType={selectedRecovry.reportType}
            closeModale={handleListOfRecovryModale}
          />
        </DialogBody>
      </Dialog>

      <table className="mt-5 w-full">
        <thead>
          <tr>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Banque</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">N°</p>
            </th>
            <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
              <p className="text-xs tracking-wide text-gray-600">Montant</p>
            </th>
            {(selectedRecouvry.type === "Effet" ||
              selectedRecouvry.type === "Cheque") && (
              <th className="border-b border-gray-200 pr-20 pb-[10px] dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Date</p>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-center text-sm font-bold text-navy-700">
                {recovryDetails?.Bank}
              </p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-center text-sm font-bold text-navy-700">
                {selectedRecouvry.type === "Cheque" ? recovryDetails?.Ncheque : recovryDetails?.NEffet}
              </p>
            </td>
            <td className="pt-[14px] pb-[18px] sm:text-[14px]">
              <p className="text-center text-sm font-bold text-navy-700">
                {parseFloat(recovryDetails?.Montant).toFixed(2)}
              </p>
            </td>
            {(selectedRecouvry.type === "Effet" ||
              selectedRecouvry.type === "Cheque") && (
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <div className="flex">
                  <p className="text-sm font-bold text-navy-700">{recovryDetails?.DateEcheance}</p>
                  {
                    accessList?.Update &&
                    <HiPencilAlt
                      size={20}
                      color="gray"
                      className="ml-2 mr-2 cursor-pointer"
                      onClick={() => handleEditeRecovryModale(selectedRecouvry.id, recovryDetails, selectedRecouvry.type === "Effet" ? "Effet" : "Cheque")}
                    />
                  }
                  <HiOutlineAdjustments
                    size={20}
                    color="gray"
                    className="ml-2 mr-2 cursor-pointer"
                    onClick={() => handleListOfRecovryModale(selectedRecouvry.id, recovryDetails, selectedRecouvry.type === "Effet" ? "Effet" : "Cheque")}
                  />
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ReportDetails;

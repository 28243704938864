import React, { useEffect } from 'react'
import { Alert, Button } from "@material-tailwind/react";
import { pdfDevisGeneratorApi, pdfVisiteEssayGeneratorApi } from 'api/pdf';

function OperationReports({ closePopup, operationDetails }) {
  // const handleCancelOp = () => {
  //   try {
  //     closePopup("Close");
  //   } catch (err) {}
  // };

  const handleDownloadDevisReport = async (id, category, index, MO) => {
    try {
      if(MO === "Devis") {
        const response = await pdfDevisGeneratorApi(id, category, index)
        window.open(`${response}`, '_blank')
      }
      if(MO === "VisiteEssai") {
        const response = await pdfVisiteEssayGeneratorApi(id)
        window.open(`${response}`, '_blank')
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    console.log("=>", operationDetails)
  }, [operationDetails])

  return (
    <div>
      <div className='my-5 grid grid-cols-1 gap-5 md:grid-cols-5'>
        {
          Object.keys(operationDetails.Rapport).map((category, index) => (
            <Button variant="gradient" className="rounded-full" color="cyan" onClick={() => handleDownloadDevisReport(operationDetails._id, category, index, operationDetails.ModeOperation)}>{category}</Button>
          ))
        }
      </div>
    </div>
  )
}

export default OperationReports

import API_URL from '../config';
import axios from 'axios';

// import { socket } from "./../socket";
import { getCookie } from './../utils/cookies';

export const userAccess = async (email) => {
    try {
        const res = await axios.get(`${API_URL}/api/V2/superAdmin/userAccess?email=${email}`);
        return res.data;
    }
    catch (err) {
        throw err;
    }
}

export const signInApi = async (email, password) => {
    try {
        const userObject = {
          email: email,
          password: password
        };

        const res = await axios.post(`${API_URL}/api/V2/superAdmin/signIn`, userObject);

        return res.data;
    }
    catch (err) {
        throw err;
    }
}

export const signInOperatorApi = async (email, password) => {
    try {
        const userObject = {
            Email: email,
            Password: password
          };
  
          const res = await axios.post(`${API_URL}/api/V2/operators/signIn`, userObject);
  
          return res.data;
    }
    catch (err) {
        throw err;
    }
}

export const isAuthenticatedApi = async() => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")
        const userRole = getCookie("lv9u765qzk1gr4fn1e")

        if(userRole === "TRUE") {
            const option = {
                headers: {
                    Authorization: `Bearer ${token}`
                    //   Authorization: `Bearer ${token}`
                }
            }

            const res = await axios.get(`${API_URL}/api/V2/superAdmin/profile/${id}/${iv}`, option);
    
            if(res.data)
                return true
        }
        else if(userRole === "FALSE") {
            const option = {
                headers: {
                    Authorization: `Bearer ${token}`
                    //   Authorization: `Bearer ${token}`
                }
            }
    
            const res = await axios.get(`${API_URL}/api/V2/operators/profile/${id}/${iv}`, option);
    
            if(res.data)
                return true
        }
    }
    catch (err) {
        return false
    }
}

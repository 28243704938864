import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
} from "@material-tailwind/react";

import NewCategory from "./New_category";
import NewDepot from "./New_depot";

import { getAllProductsCategoriesApi } from "api/filtersData";
import { getAllDepotsApi } from "api/depot";
import { addNewProductApi } from "api/products";
import { getOneProductApi } from "api/products";
import { updateProductApi } from "api/products";

import { ToastContainer, toast } from "react-toastify";
import { getAllNonCounterDepots } from "api/products";

function ProductForm({ closePopup, clienFormAction, showToast, productID }) {
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showDepotModal, setShowDepotModal] = useState(false);

  const [productsCategories, setProductsCategories] = useState([]);

  const [productDepot, setProductDepot] = useState([]);
  const [productFormData, setProductFormData] = useState({
    Code: "",
    Name: "",
    Unity: "",
    Packaging: "",
    isActive: true,
    Price: 0,
    HaveTVA: 0,
    sumOfStock: 0,
    PriceTTC: 0,
    Remise: 0,
    Categorie: "",
    CategorieID: "",
    CodeProduitComptable: "",
    ExperationDate: "",
    LotNumber: [],
    AlertStock: 0,
  });

  useEffect(() => {
    getAllProductsCategories("");
    getAllDepos("", "");

    getProductDetails();
  }, [productID]);

  useEffect(() => {
    setProductFormData((prevState) => ({
      ...prevState,
      // PriceTTC: parseFloat(prevState.PriceTTC) + (parseFloat(prevState.PriceTTC) * (prevState.PriceTTC(prevState.HaveTVA) / 100))
      PriceTTC: parseFloat(prevState.Price) + parseFloat(prevState.Price) * (parseFloat(prevState.HaveTVA) / 100),
    }));
  }, [productFormData.Price, productFormData.HaveTVA]);

  const getProductDetails = async () => {
    try {
      if (clienFormAction !== "add") {
        console.log(productID);
        const productDtails = await getOneProductApi(productID);

        setProductFormData({
          Code: productDtails.Code,
          Name: productDtails.Name,
          Unity: productDtails.Unity,
          Packaging: productDtails.Packaging,
          isActive: productDtails.isActive,
          Price: productDtails.Price,
          HaveTVA: productDtails.HaveTVA,
          sumOfStock: productDtails.sumOfStock,
          PriceTTC: productDtails.PriceTTC,
          Remise: productDtails.Remise,
          Categorie: productDtails.Categorie,
          CategorieID: productDtails.CategorieID,
          CodeProduitComptable: productDtails.CodeProduitComptable,
          ExperationDate: productDtails.ExperationDate,
          LotNumber: productDtails.LotNumber,

          Stock: productDtails.Stock,
          Disponible: productDtails.Disponible,
          AlertStock: 0,
        });

        setProductDepot(productDtails.Stock);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductFormData(prevState => {
      const newState = { ...prevState, [name]: value };

      return newState;
    });
  };

  const getAllProductsCategories = async (categoryName) => {
    try {
      const productsCategories = await getAllProductsCategoriesApi(
        categoryName
      );
      setProductsCategories(productsCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllDepos = async (Name, Origin) => {
    try {
      const depotsList = await getAllDepotsApi(Name, Origin);
      const listOfDepot = [];

      depotsList.map((el) => {
        listOfDepot.push({
          City: el.Name,
          Disponible: 0,
          fromDepot: el._id,
        });
      });

      setProductDepot(listOfDepot);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNewCategoryModal = (action) => {
    try {
      if (action === "Reload") getAllProductsCategories("");

      setShowCategoryModal(!showCategoryModal);
    } catch (err) {
      console.log(err);
    }
  };
  const handleNewDepotModal = (action) => {
    try {
      if (action === "Reload") getAllDepos("", "");

      setShowDepotModal(!showDepotModal);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCancelOp = () => {
    try {
      closePopup("Close");
    } catch (err) {}
  };

  const handleAddNewProduct = async () => {
    try {
      const selectedCategory = productsCategories.find(
        (el) => el._id === productFormData?.CategorieID
      );

      const productObject = productFormData;
      productObject.Stock = productDepot;
      productObject.Disponible = 0;
      productObject.Categorie = selectedCategory?.Name;

      productDepot.map((el) => {
        productObject.Disponible += parseFloat(el.Disponible);
      });

      // validation
      if (!selectedCategory || !selectedCategory?.Name)
        return showToast("error", `Sélectionner votre category`);

      await addNewProductApi(productObject);
      showToast("success", "Produit ajoutée");
    } catch (err) {
      console.log(err);
      showToast("error", `${err?.response?.data?.error}`);
    }
  };

  const handleEditeProduct = async () => {
    try {
      const nonCounterDepotsRes = await getAllNonCounterDepots()
      const nonCounterDepots = nonCounterDepotsRes.map(el => el.Name)

      const productObject = productFormData;
      productObject.Stock = productDepot;
      productObject.Disponible = 0;

      productDepot.map((el) => {
        if(!nonCounterDepots.includes(el.City))
          productObject.Disponible += parseFloat(el.Disponible);
      });

      // validation
      if (productFormData.Categorie === "")
        return showToast("error", `Sélectionner votre category`);

      await updateProductApi(productObject, productID);
      showToast("success", "Produit modifiée");
    } catch (err) {
      showToast("error", `${err?.response?.data?.error}`);
    }
  };

  const handleDepotIputChange = (index, event) => {
    try {
      const { value } = event.target;
      const updatedDepotList = [...productDepot];
      updatedDepotList[index].Disponible = parseFloat(value);
      setProductDepot(updatedDepotList);
    } catch (err) {
      console.log(err);
    }
  };

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      // setShowDepotModal(!showDepotModal);
      // toast.success(message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
    if (type === "error") {
      // toast.error(message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
    }
  };

  return (
    <form
      style={{
        overflow: "scroll",
        height: "80vh",
      }}
    >
      <ToastContainer />
      <Dialog
        size="xl"
        open={showCategoryModal}
        handler={handleNewCategoryModal}
      >
        <DialogHeader>
          <p className="w-full text-center">Nouvelle catégorie</p>
        </DialogHeader>
        <DialogBody>
          <NewCategory closePopup={handleNewCategoryModal} />
        </DialogBody>
      </Dialog>

      <Dialog size="xl" open={showDepotModal} handler={handleNewDepotModal}>
        <DialogHeader>
          <p className="w-full text-center">Nouvelle dépôt</p>
        </DialogHeader>
        <DialogBody>
          <NewDepot
            closePopup={handleNewDepotModal}
            showToast={hadleShowToast}
          />
        </DialogBody>
      </Dialog>

      {clienFormAction !== "edite" && clienFormAction !== "show" ? (
        <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Catégorie
            </label>
            <select
              label="select Version"
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="CategorieID"
              value={productFormData.CategorieID}
              onChange={handleInputChange}
            >
              <option value="">Séléctioner une catégorie</option>
              {productsCategories.map((el) => (
                <option value={el._id}>{el.Name}</option>
              ))}
            </select>
          </div>
          <div>
            <Button
              variant="text"
              color="#8bc34a"
              onClick={() => handleNewCategoryModal()}
              className="mt-7"
            >
              <span>Ajouter une Catégorie</span>
            </Button>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code du produit
          </label>
          <input
            type="text"
            id=""
            placeholder="Code du produit"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="Code"
            value={productFormData.Code}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Produit
          </label>
          <input
            type="text"
            id=""
            placeholder="Produit"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="Name"
            value={productFormData.Name}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Unité
          </label>
          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            name="Unity"
            value={productFormData.Unity}
            onChange={handleInputChange}
          >
            <option disabled>Séléctioner l'unité</option>
            <option value="KG">KG</option>
            <option value="L">L</option>
            <option value="Unite">Unite</option>
          </select>
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Emballage
          </label>
          <input
            type="text"
            id=""
            placeholder="Emballage"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="Packaging"
            value={productFormData.Packaging}
            onChange={handleInputChange}
          />
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Prix HT : en DH
          </label>
          <input
            type="number"
            id=""
            placeholder="Prix HT : en DH"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="Price"
            value={productFormData.Price}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            TVA %
          </label>
          <input
            type="number"
            id=""
            placeholder="TVA %"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="HaveTVA"
            value={productFormData.HaveTVA}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Prix TTC
          </label>
          <input
            type="number"
            id=""
            placeholder="Prix TTC"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="PriceTTC"
            value={productFormData.PriceTTC}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Remise %
          </label>
          <input
            type="number"
            id=""
            placeholder="Remise %"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="Remise"
            value={productFormData.Remise}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Code comptable de produit
          </label>
          <input
            type="text"
            id=""
            placeholder="Code comptable de produit"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="CodeProduitComptable"
            value={productFormData.CodeProduitComptable}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Date expiration
          </label>
          <input
            type="date"
            id=""
            placeholder="Date expiration"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="ExperationDate"
            value={
              clienFormAction === "add"
                ? productFormData.ExperationDate
                : productFormData?.ExperationDate?.split("T")[0]
            }
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Numéro de lot
          </label>

          <select
            label="select Version"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="LotNumber"
            value={productFormData.LotNumber}
            onChange={handleInputChange}
          >
            <option>Séléctionner le numéro de lot</option>
            <option>Numéro de lot 1</option>
            <option>Numéro de lot 2</option>
            <option>Numéro de lot 3</option>
          </select>
          {/* <input
            type="text"
            id=""
            placeholder="Numéro de lot"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="LotNumber"
            value={productFormData.LotNumber}
            onChange={handleInputChange}
          /> */}
        </div>

        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Stock d'alerte
          </label>
          <input
            type="number"
            min="0"
            id=""
            placeholder="Stock d'alerte"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            disabled={clienFormAction === "show"}
            name="AlertStock"
            value={productFormData.AlertStock}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <p className="mt-5 text-xl font-bold text-navy-700">Dépôt</p>

      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-5">
        {productDepot.map((el, index) => (
          <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              {el.City}
            </label>
            <input
              type="number"
              min="0"
              id=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              disabled={clienFormAction === "show"} // || clienFormAction === "edite"
              value={el.Disponible}
              onChange={(event) => handleDepotIputChange(index, event)}
            />
          </div>
        ))}

        {clienFormAction !== "edite" && clienFormAction !== "show" ? (
          <div>
            <Button
              variant="text"
              color="#8bc34a"
              onClick={() => handleNewDepotModal()}
              className="mt-5"
            >
              <span>Ajouter un dépôt</span>
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>

      {clienFormAction === "edite" && (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#8bc34a"
            onClick={() => handleEditeProduct()}
            className="mr-1"
          >
            <span>Modifier</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}

      {clienFormAction === "add" && (
        <div className="mt-5">
          <Button
            variant="gradient"
            color="#8bc34a"
            onClick={() => handleAddNewProduct()}
            className="mr-1"
          >
            <span>Ajouter</span>
          </Button>
          <Button
            variant="text"
            color="#cf503f"
            onClick={() => handleCancelOp()}
            className="mr-1"
          >
            <span>Annuler</span>
          </Button>
        </div>
      )}
    </form>
  );
}

export default ProductForm;

import React, { useEffect, useState } from "react";
import Card from "components/card";

import BeforeInvoce from "./ReportType/Before_invoce";
import BL from "./ReportType/BL";
import BR from "./ReportType/BR";
import AfterInvoce from "./ReportType/After_invoce";

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

function ListOfReports({ accessList }) {
  const [selectedTab, setSelectedTab] = useState('')

  const [TabsData, setTabsData] = useState([])

  const [filterInputs, setFilterInputs] = useState({
    MP: "Vente",
    clientName: "",
    etatMagasin: ""
  })

  useEffect(() => {
    // setTabsData([
    //   {
    //     label: "Rapports avant facturation",
    //     value: "BF",
    //     desc: <BeforeInvoce selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
    //   },
    //   {
    //     label: "BL",
    //     value: "BL",
    //     desc: <BL selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
    //   },
    //   {
    //     label: "BR",
    //     value: "BR",
    //     desc: <BR selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
    //   },
    //   {
    //     label: "Rapports après facturation",
    //     value: "AF",
    //     desc: <AfterInvoce selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
    //   }
    // ])
    getInitialData()
  }, [selectedTab, filterInputs, accessList])

  const getInitialData = () => {
    const inventoryManagementTabs = []

    if(accessList.BI) {
      inventoryManagementTabs.push(
        {
          label: "Rapports avant facturation",
          value: "BF",
          desc: <BeforeInvoce selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
        }
      )
    }
    if(accessList.BL) {
      inventoryManagementTabs.push(
        {
          label: "Bon de livraison",
          value: "BL",
          desc: <BL selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
        }
      )
    }
    if(accessList.BR) {
      inventoryManagementTabs.push(
        {
          label: "Bon de réception",
          value: "BR",
          desc: <BR selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
        }
      )
    }
    if(accessList.AI) {
      inventoryManagementTabs.push(
        {
          label: "Rapports après facturation",
          value: "AF",
          desc: <AfterInvoce selected={selectedTab} filterInputs={filterInputs} accessList={accessList} />,
        }
      )
    }

    setTabsData(inventoryManagementTabs)
  }

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName)
  }

  const handleInputChange = e => {
    const {name, value} = e.target
    setFilterInputs({...filterInputs, [name]: value})
  }

  return (
    <div>
      <Card extra={"w-full pb-10 p-4 h-full"}>
        <header className="relative flex items-center justify-between">
          <div></div>
        </header>

        <div className="grid grid-cols-1 gap-5 md:grid-cols-3">
          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par mode d'opération
            </small>
            <div>
              <select
                label="select Version"
                className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                defaultValue="Tout"

                name="MP"
                value={filterInputs.MP}
                onChange={handleInputChange}
              >
                {/* <option value="">Tout</option> */}
                <option value="Vente">Vente</option>
                <option value="Avoir">Avoir</option>
              </select>
            </div>
          </div>

          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par statut
            </small>
            <div>
              <select
                label="select Version"
                className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
                defaultValue="Tout"

                name="etatMagasin"
                value={filterInputs.etatMagasin}
                onChange={handleInputChange}
              >
                <option value="">
                  Tout
                </option>
                <option value="V">Validée</option>
                <option value="A">Annulée</option>
                <option value="N">En cours</option>
              </select>
            </div>
          </div>

          <div>
            <small className="text-sm font-medium text-gray-600 dark:text-white">
              Filtrer par client
            </small>
            <div>
              <input
                type="text"
                id=""
                placeholder=""
                className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                name="clientName"
                value={filterInputs.clientName}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
          <Tabs value="BF">
            <TabsHeader className="flex flex-col md:flex-row">
              {TabsData.map(({ label, value }) => (
                <Tab key={value} value={value} className="mb-5 md:mb-0" onClick={() => handleTabClick(value)}>
                  <p className="font-semibold text-gray-700 dark:text-indigo-500">
                    {label}
                  </p>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody>
              {TabsData.map(({ value, desc }) => (
                <TabPanel key={value} value={value} style={{ position: 'relative', zIndex: 55 }}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </Card>
    </div>
  );
}

export default ListOfReports;

import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const addNewDepotApi = async(Name, Origin) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            Name: Name,
            Origin: Origin,
            Operators: []
        }

        const res = await axios.post(`${API_URL}/api/V2/Depot/addDepot/${id}/${iv}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateDepotCouterStatusApi = async (Status, depotId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            StockCounter: Status
        }

        const res = await axios.put(`${API_URL}/api/V2/Depot/updateDepot/${id}/${iv}/${depotId}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const updateDepotApi = async(Name, Origin, depotId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const objectData = {
            Name: Name,
            Origin: Origin
        }

        const res = await axios.put(`${API_URL}/api/V2/Depot/updateDepot/${id}/${iv}/${depotId}`, objectData, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getAllDepotsApi = async(Name = "", Origin = "") => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/V2/Depot/getAllDepots/${id}/${iv}?Name=${Name}&Origin=${Origin}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const deleteDepotApi = async(depotId) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.delete(`${API_URL}/api/V2/Depot/deleteOneDepot/${id}/${iv}/${depotId}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

import API_URL from "../config";
import axios from "axios";

// import { socket } from "./../socket";
import { getCookie } from "./../utils/cookies";

export const productTableApi = async (tcp, client, fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/ProductsTable/${id}/${iv}?TCP=${tcp}&Client=${client}&filtredDate=["${fromDate}", "${toDate}"]`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/ProductsTable/${id}/${iv}?TCP=${tcp}&Client=${client}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const productTableXcelApi = async (tcp, client, fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/ProductsTableToExcel/${id}/${iv}?TCP=${tcp}&Client=${client}&filtredDate=["${fromDate}", "${toDate}"]&filterBy=${client}`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/ProductsTableToExcel/${id}/${iv}?TCP=${tcp}&Client=${client}&filterBy=${client}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const tcpTableApi = async (selectedProduct, fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/TcpTableByProductCategory/${id}/${iv}?selectedProduct=${selectedProduct}&filtredDate=["${fromDate}", "${toDate}"]&filterBy=${selectedProduct}`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/TcpTableByProductCategory/${id}/${iv}?selectedProduct=${selectedProduct}&filterBy=${selectedProduct}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const tcpTableXcelApi = async (selectedProduct, fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/TcpTableByProductCategoryToExcel/${id}/${iv}?selectedProduct=${selectedProduct}&filtredDate=["${fromDate}", "${toDate}"]&filterBy=${selectedProduct}`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/TcpTableByProductCategoryToExcel/${id}/${iv}?selectedProduct=${selectedProduct}&filterBy=${selectedProduct}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const generaleTableApi = async (fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/GeneraleTable/${id}/${iv}?filtredDate=["${fromDate}", "${toDate}"]`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/GeneraleTable/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

export const generaleTableXcelApi = async (fromDate, toDate) => {
  try {
    const token = getCookie("T6hVzR7c0g3maM3F");
    const iv = getCookie("02hfCmUqpk66w4uG");
    const id = getCookie("3F2Mb2pJ1YRro5Z4");

    const option = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    if (fromDate && toDate) {
      const res = await axios.get(
        `${API_URL}/api/V2/dashboard/GeneraleTableToExcel/${id}/${iv}?filtredDate=["${fromDate}", "${toDate}"]`,
        option
      );
      return res.data;
    }

    const res = await axios.get(
      `${API_URL}/api/V2/dashboard/GeneraleTableToExcel/${id}/${iv}`,
      option
    );
    return res.data;
  } catch (err) {
    return false;
  }
};

import { Button, Spinner } from '@material-tailwind/react';
import { addNewCompanyFeeCityApi } from 'api/companyFeesCities';
import React, { useState } from 'react'

function NewCityModale({ closePopup }) {
    const [isLoading, setIsLoading] = useState(false)

    const [inputFormValue, setInputFormValue] = useState({
      Name: ""
    })

    const handleCancelOp = () => {
        closePopup("Close");
    }

    const handleAddNewCity = async () => {
      try {
        setIsLoading(true)
        await addNewCompanyFeeCityApi(inputFormValue)
      }
      catch (err) {
        console.log(err);
      }
      finally {
        setIsLoading(false)
        closePopup("Close");
      }
    }

    const handleInputFormValueChange = e => {
      const {name, value} = e.target
      setInputFormValue({
        ...inputFormValue,
        [name]: value
      })

      if(name == "Name") inputFormValue.Name = value
    }

  return (
    <div>
        <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-2">
            <div>
            <label htmlFor="" className="text-md font-bold text-navy-700">
              Point :
            </label>
            <input
              type="text"
              id=""
              placeholder="Point"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

              name='Name'
              value={inputFormValue.Name}
              onChange={handleInputFormValueChange}
            />
            </div>
        </div>

        {
          isLoading ?
          <div className="flex w-full items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <div className="mt-5">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleAddNewCity()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </div>
        }
    </div>
  )
}

export default NewCityModale

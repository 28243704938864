import API_URL from '../config';
import axios from 'axios';

import { getCookie } from './../utils/cookies';

export const getLVJApi = async(filterObject, Page) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/structredRapportForLVJ_V2/${id}/${iv}?TCP=${filterObject.TCP || ""}&Client=${filterObject.Client || ""}&CodeClient=${filterObject.CodeClient || ""}&SelectedInvoce=${filterObject.SelectedInvoce || ""}&FromYear=${filterObject.FromYear || ""}&ToYear=${filterObject.ToYear || ""}&Type=${filterObject.Type || ""}&limit=10&page=${Page}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

export const getLVJxcelApi = async(filterObject) => {
    try {
        const token = getCookie("T6hVzR7c0g3maM3F")
        const iv = getCookie("02hfCmUqpk66w4uG")
        const id = getCookie("3F2Mb2pJ1YRro5Z4")

        const option = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }

        const res = await axios.get(`${API_URL}/api/rapport/structredRapportForLVJ_V2_ToXcel/${id}/${iv}?TCP=${filterObject.TCP || ""}&Client=${filterObject.Client || ""}&CodeClient=${filterObject.CodeClient || ""}&SelectedInvoce=${filterObject.SelectedInvoce || ""}&FromYear=${filterObject.FromYear || ""}&ToYear=${filterObject.ToYear || ""}&Type=${filterObject.Type || ""}`, option);
        return res.data
    }
    catch (err) {
        throw err
    }
}

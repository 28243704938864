const FreeCard = () => {
  return (
    <div className="relative mt-1 flex w-[256px] justify-center rounded-[20px] bg-gradient-to-br from-[#868CFF] via-[#432CF3] to-brand-500 pb-4">
      <div className="mt-1 flex h-fit flex-col items-center">
        {/* <p className="text-lg font-bold text-white">Upgrade to PRO</p> */}
        <p className="mt-1 px-4 text-center text-sm text-white">
          Improve your development process and start doing more with Horizon UI
          PRO!
        </p>
        <a
          target="blank"
          className="text-medium mt-1 block rounded-full bg-gradient-to-b from-white/50 to-white/10 py-[12px] px-11 text-center text-base text-white hover:bg-gradient-to-b hover:from-white/40 hover:to-white/5 "
          href="https://horizon-ui.com/pro?ref=live-free-tailwind-react"
        >
          Upgrade to PRO
        </a>
      </div>
    </div>
  );
};

export default FreeCard;

import React, { useEffect, useState } from "react";
import Card from "components/card";
import { Button, Spinner, IconButton } from "@material-tailwind/react";

import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";

import { FaTrash } from "react-icons/fa";
import { MdPictureAsPdf } from "react-icons/md";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Dialog, DialogHeader, DialogBody } from "@material-tailwind/react";

// import DepotForm from "./Depot_form";
import TransfertForm from "./Transfert_form";

import { ToastContainer, toast } from "react-toastify";
import { getAllStocks } from "api/stock";
import { getOneStockApi } from "api/stock";
import { updateStockApi } from "api/stock";
import { deleteStockTransfertApi } from "api/stock";
import { pdfStockGeneratorApi } from "api/pdf";
import { getAllDepotsApi } from "api/depot";

function TransfertTable({ accessList }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showTransfertForm, setTransfertForm] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [active, setActive] = useState(1);
  const [currentPagination, setCurrentPagination] = useState(1);

  const [depotList, setDepotList] = useState([]);

  const [numberOfPages, setNumberOfPages] = useState(0);
  const [numberOfPaginations, setNumberOfPaginations] = useState(0);
  const [numberOfReports, setNumberOfReports] = useState(0);

  const [inputFilterValue, setInputFilterValue] = useState({
    Depot: "",
    TCP: "",
    FromYear: `${new Date().getFullYear()}-01-01`,
    ToYear: `${new Date().getFullYear() + 1}-01-01`,
    Product: "",
    Category: "",
  });

  useEffect(() => {
    getDepotsData();
    getTableData(
      ["TranfertEntry"],
      inputFilterValue.Depot,
      inputFilterValue.TCP,
      "",
      inputFilterValue.FromYear,
      inputFilterValue.ToYear,
      inputFilterValue.Product,
      inputFilterValue.Category,
      active
    );
  }, []);

  const getDepotsData = async () => {
    try {
      const response2 = await getAllDepotsApi();
      setDepotList(response2);
    } catch (err) {
      console.log(err);
    }
  };

  const getTableData = async (
    type,
    Depot,
    TCP,
    EnterOrExit,
    FromYear,
    ToYear,
    Product,
    Category,
    page
  ) => {
    try {
      setIsLoading(true);
      const response = await getAllStocks(
        type,
        Depot,
        TCP,
        EnterOrExit,
        FromYear,
        ToYear,
        Product,
        Category,
        page
      );
      setTableData(response?.data);

      setNumberOfPages(response?.pagination?.numberOfPages);
      setNumberOfPaginations(response?.pagination?.numberOfPaginations);
      setNumberOfReports(response?.pagination?.numberOfClients);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltersInputChange = (e) => {
    const { name, value } = e.target;
    setInputFilterValue({ ...inputFilterValue, [name]: value });
    setCurrentPagination(1);

    getTableData(
      ["TranfertEntry"],
      name == "Depot" ? value : inputFilterValue.Depot,
      name == "TCP" ? value : inputFilterValue.TCP,
      "",
      name == "FromYear" ? value : inputFilterValue.FromYear,
      name == "ToYear" ? value : inputFilterValue.ToYear,
      name == "Product" ? value : inputFilterValue.Product,
      name == "Category" ? value : inputFilterValue.Category,
      active
    );
  };

  const handleDeleteDepot = (id) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Supprimer ?",
          confirmButtonText: "Supprimer",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              setIsLoading(true);

              const oneStock = await getOneStockApi(id);

              const stockObject = [];
              stockObject.push({
                Name: oneStock.Product,
                FromD: oneStock.ToDepot,
                ToD: oneStock.FromDepot,
                Quantity: oneStock.Quantity,
                Type: "TranfertEntry",
                Comment: "",
              });
              await updateStockApi(stockObject, "");
              await deleteStockTransfertApi(id);

              Swal.fire("Supprimée", "", "success");

              getTableData(["TranfertEntry"]);
            } catch (err) {
              toast.error(err?.response?.data?.error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            } finally {
              setIsLoading(false);
            }
          }
        });
    } catch (err) {
      console.log(err?.response?.data?.error);
    }
  };

  const handleDepotModal = (action) => {
    try {
      setTransfertForm(!showTransfertForm);
    } catch (err) {
      console.log(err);
    }
  };

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      setTransfertForm(!showTransfertForm);

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      getTableData(["TranfertEntry"]);
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDownloadReport = async (data) => {
    try {
      const response = await pdfStockGeneratorApi(
        data._id,
        data.FromDepot,
        data.ToDepot
      );
      window.open(`${response}`, "_blank");
    } catch (err) {}
  };

  const getItemProps = (index) => ({
    variant: active === index ? "filled" : "text",
    color: "gray",
    onClick: () => {
      getTableData(
        ["TranfertEntry"],
        inputFilterValue.Depot,
        inputFilterValue.TCP,
        "",
        inputFilterValue.FromYear,
        inputFilterValue.ToYear,
        inputFilterValue.Product,
        inputFilterValue.Category,
        active
      );
      setActive(index);
    },
  });

  const next = () => {
    if (currentPagination === numberOfPaginations) return;
    setCurrentPagination(currentPagination + 1);
  };

  const prev = () => {
    if (currentPagination === 1) return;
    setCurrentPagination(currentPagination - 1);
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <ToastContainer />
      <Dialog size="xl" open={showTransfertForm} handler={handleDepotModal}>
        <DialogHeader>
          <p className="w-full text-center">Nouveau (Transfer)</p>
        </DialogHeader>
        <DialogBody>
          <TransfertForm
            closePopup={handleDepotModal}
            showToast={hadleShowToast}
          />
        </DialogBody>
      </Dialog>

      <header className="relative mb-4 flex flex-col items-center justify-between md:flex-row">
        <div className="mb-5 text-xl font-bold text-navy-700 dark:text-white md:mb-0"></div>
        {accessList.Add && (
          <Button
            onClick={() => handleDepotModal(null)}
            className="linear mb-5 rounded-[10px] bg-gray-500 px-4 py-2 text-xs font-bold text-white transition duration-200 hover:bg-gray-400 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 md:mb-0"
          >
            Nouveau transfert
          </Button>
        )}
      </header>

      <div className="mt-5 grid grid-cols-1 gap-3 md:grid-cols-3">
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par produit :
          </small>
          <div>
            <input
              type="text"
              id=""
              placeholder="Tout"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="Product"
              value={inputFilterValue.Product}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par depôt :
          </small>
          <div>
            <select
              label="select Version"
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              defaultValue=""
              name="Depot"
              value={inputFilterValue.Depot}
              onChange={handleFiltersInputChange}
            >
              <option value="">Tout</option>
              {depotList.map((el) => (
                <option value={el.Name}>{el.Name}</option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <small className="text-sm font-medium text-gray-600 dark:text-white">
            Filtrer par date (depuis - à) :
          </small>
          <div>
            <input
              type="date"
              id=""
              placeholder=""
              className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="FromYear"
              value={inputFilterValue.FromYear}
              onChange={handleFiltersInputChange}
            />
            <input
              type="date"
              id=""
              placeholder=""
              className={`mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
              name="ToYear"
              value={inputFilterValue.ToYear}
              onChange={handleFiltersInputChange}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 overflow-x-scroll">
        {isLoading ? (
          <div className="flex w-full items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : (
          <div
            className="mt-8 overflow-x-scroll"
            style={{ height: "50vh", overflowY: "scroll" }}
          >
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Numéro de transfert
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">Date</p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Dépôt de sortie
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Dépôt d'entrée
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Produit
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Quantité
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Télécharger
                    </p>
                  </th>
                  <th className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700">
                    <p className="text-xs tracking-wide text-gray-600">
                      Supprimer
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody>
                {tableData.map((el) => (
                  <tr>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.TransfertFullNumber}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.CurrentDate.split("T")[0]}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.FromDepot}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.ToDepot}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Product}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {el.Quantity}
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        <div className="flex">
                          {accessList.Read && (
                            <MdPictureAsPdf
                              size={30}
                              className="cursor-pointer"
                              onClick={() => handleDownloadReport(el)}
                            />
                          )}
                        </div>
                      </p>
                    </td>
                    <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        <div className="flex">
                          {accessList.Delete && (
                            <FaTrash
                              size={20}
                              color="#cf503f"
                              className="cursor-pointer"
                              onClick={() => handleDeleteDepot(el._id)}
                            />
                          )}
                        </div>
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {!isLoading && numberOfReports != 0 && (
        <div className="mt-4 flex items-center gap-4">
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={prev}
            disabled={currentPagination === 1}
          >
            <IoMdArrowDropleftCircle size={30} />
          </Button>
          <div className="flex items-center gap-2">
            {currentPagination !== numberOfPaginations
              ? // paginations row
                Array.from({
                  length: numberOfPages > 5 ? 5 : numberOfPages,
                }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })
              : // last pagination row
                Array.from({ length: numberOfPages % 5 }).map((el, index) => {
                  const pageNumber = index + 1 + (currentPagination - 1) * 5;
                  return (
                    <IconButton {...getItemProps(pageNumber)}>
                      {pageNumber}
                    </IconButton>
                  );
                })}
          </div>
          <Button
            variant="text"
            className="flex items-center gap-2"
            onClick={next}
            disabled={
              currentPagination === numberOfPaginations ||
              numberOfPaginations === 0
            }
          >
            <IoMdArrowDroprightCircle size={30} />
          </Button>
        </div>
      )}
    </Card>
  );
}

export default TransfertTable;

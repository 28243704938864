import React, { useEffect, useState } from 'react'
import { Button } from '@material-tailwind/react';
import { addNewCategoryApi, updateCategoryApi } from 'api/category';

function CategoryForm({ closePopup, categoryFormAction, showToast, selectedCategory }) {
  const [categoryName, setCategoryName] = useState('')

  useEffect(() => {
    getInitialData()
  }, [selectedCategory])

  const getInitialData = () => {
    try {
      setCategoryName(selectedCategory.Name)
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleAddCategory = async () => {
    try {
      await addNewCategoryApi(categoryName)
      showToast('success', 'Dépôt Ajoutée')
      closePopup("Reload");
    }
    catch (err) {
      showToast('error', `${err?.response?.data?.error}`)
    }
  }

  const handleEditeCategory = async () => {
    try {
      await updateCategoryApi(categoryName, selectedCategory._id)
      showToast('success', 'Catégory Modifiée')
      closePopup("Reload");
    }
    catch (err) {
      showToast('error', `${err?.response?.data?.error}`)
    }
  }

  const handleCancelOp = () => {
    closePopup("Close");
  };

  return (
    <form>
      <div className="mt-2 grid grid-cols-1 gap-5 md:grid-cols-3">
        <div>
          <label htmlFor="" className="text-md font-bold text-navy-700">
            Nom de la catégorie
          </label>
          <input
            type="text"
            id=""
            placeholder="Nom de la catégorie"
            className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}
            value={categoryName}
            disabled={categoryFormAction === "show"}

            onChange={e => setCategoryName(e.target.value)}
          />
        </div>
      </div>

      {
        categoryFormAction === "edite" &&
        <div className="mt-5">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleEditeCategory()}
          className="mr-1"
        >
          <span>Modifier</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
        </div>
      }

      {
        categoryFormAction === "add" &&
        <div className="mt-5">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleAddCategory()}
          className="mr-1"
        >
          <span>Ajouter</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelOp()}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
        </div>
      }
    </form>
  )
}

export default CategoryForm

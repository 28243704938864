import { Button, Spinner } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { getOneReportDetailsApi } from "api/gestionStock";

import { ToastContainer, toast } from "react-toastify";
import { updateReportDataApi, updateFCreatedTApi, fixMgReportNumbersApi } from "api/gestionStock";
import { getAllProductsApi } from "api/products";

function StockInfo({ selectedReportId, popupHandler, showToast }) {
  const [reportDetails, setReportDetails] = useState({});
  const [reportCategories, setReportCategories] = useState([]);

  const [inputValues, setInputValues] = useState([]);

  const [productStockList, setProductStockList] = useState([])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getReportDetails(selectedReportId);
  }, [selectedReportId]);

  const hadleShowToast = (type, message) => {
    if (type === "success") {
      popupHandler('reload')

      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  // const handleStockValidation = async () => {
  //   try {
  //     const errosList = []
  //     const selectedStock = []

  //     // check the quantities is same
  //     inputValues.map(el => {
  //       if(el.quantiteByUnity != el.inputQuantity) {
  //         errosList.push(`Quantité demandée et Quantité sortie ne sont pas les mêmes (${el.productName})`)
  //       }
  //     })

  //     if(errosList.length !== 0)
  //       return hadleShowToast('error', errosList[0])

  //     // check if stock selected
  //     inputValues.map(el => {
  //       if(el.city == "")
  //         errosList.push(`Selectionner le stock pour ${el.productName}`)
  //     })

  //     if(errosList.length !== 0)
  //       return hadleShowToast('error', errosList[0])

  //     // check if stock is available
  //     inputValues.map(el => {
  //         const selectedDepot = el.Stock.find(el2 => el2.City === el.city)

  //         if(parseFloat(el.quantiteByUnity) > parseFloat(selectedDepot.Disponible))
  //           errosList.push(`stock insifusant pour le produit ${el.productName}`)
  //     })

  //     if(errosList.length !== 0)
  //       return hadleShowToast('error', errosList[0])

  //     inputValues.map(el => {
  //       selectedStock.push({
  //         productName: el.productName,
  //         city: el.city,
  //         unity: el.unity,
  //         quantity: el.quantiteByUnity,
  //         Code: el.Code
  //       })
  //     })

  //     const uniqueDepot = [...new Set(selectedStock.map(obj => obj.city))];

  //     const updatedObject = {
  //       NumberOfBls: uniqueDepot.length,
  //       EtatBonLivraison: "V",
  //       EtatMagasinier: "V",
  //       MagasinStock: selectedStock,
  //     };

  //     await updateReportDataApi(selectedReportId, updatedObject)
  //     await updateFCreatedTApi(selectedReportId)

  //     hadleShowToast('success', 'Ajoutée')
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleStockValidation = async () => {
    try {
      setIsLoading(true)

      const errosList = []
      const selectedStock = []

      // check the quantities is same
      inputValues.map(el => {
        if(el.quantiteByUnity != el.inputQuantity) {
          errosList.push(`Quantité demandée et Quantité sortie ne sont pas les mêmes (${el.productName})`)
        }
      })

      if(errosList.length !== 0)
        return hadleShowToast('error', errosList[0])

      // check if stock selected
      inputValues.map(el => {
        if(el.city == "")
          errosList.push(`Sélectionner le stock pour ${el.productName}`)
      })

      if(errosList.length !== 0)
        return hadleShowToast('error', errosList[0])

      // check if stock is available
      if(reportDetails.ModeOperation !== "Avoir") {
        inputValues.map(el => {
          // const selectedDepot = el.Stock.find(el2 => el2.City === el.city)
          const selectedDepot = productStockList.find(product => product.Name.trim() === el.productName.trim()).Stock.find(depot => depot.City === el.city)
          console.log("selectedDepot", selectedDepot)

          if(parseFloat(el.quantiteByUnity) > parseFloat(selectedDepot.Disponible))
            errosList.push(`stock insifusant pour le produit ${el.productName}`)
        })
      }

      if(errosList.length !== 0)
        return hadleShowToast('error', errosList[0])

      inputValues.map(el => {
        selectedStock.push({
          productName: el.productName,
          city: el.city,
          unity: el.unity,
          quantity: el.quantiteByUnity,
          Code: el.Code
        })
      })

      const uniqueDepot = [...new Set(selectedStock.map(obj => obj.city))];

      const updatedObject = {
        NumberOfBls: uniqueDepot.length,
        EtatBonLivraison: "V",
        EtatMagasinier: "V",
        MagasinStock: selectedStock,
      };

      await fixMgReportNumbersApi(selectedReportId)
      await updateReportDataApi(selectedReportId, updatedObject)
      await updateFCreatedTApi(selectedReportId)

      console.log("updatedObject", updatedObject)
      hadleShowToast('success', 'Ajoutée')
    } catch (err) {
      console.log(err);
    }
    finally {
      setIsLoading(false)
    }
  };

  const handleDepotInputChange = (index, productName, city, unity, quantity, Code) => {
    setInputValues(prevInputValues  => {
      return prevInputValues.map((inputValue, idx) => {
        if (idx === index) {
          return { ...inputValue, city: city };
        }
        return inputValue;
      });
    })
  };
  const handleQuantityInputChange = (index, productName, city, unity, quantity, Code) => {
    setInputValues(prevInputValues  => {
      return prevInputValues.map((inputValue, idx) => {
        if (idx === index) {
          console.log(productName)
          return { ...inputValue, inputQuantity: quantity };
        }
        return inputValue;
      });
    })
  };

  const getReportDetails = async (reportID) => {
    try {
      const productsList = [];

      const reportDetails = await getOneReportDetailsApi(reportID);
      setReportDetails(reportDetails);

      const reportCategories = Object.getOwnPropertyNames(reportDetails.Rapport);
      setReportCategories(reportCategories);

      reportCategories.map((el, index) => {
        reportDetails.Rapport[el].produits.map(el2 => {
          productsList.push({
            productName: el2.name,
            city: "",
            unity: el2.unity,
            quantiteByUnity: el2.quantiteByUnity,
            inputQuantity: 0,
            Code: el2.Code,
            Stock: el2.Stock
          });
        })
      });

      setInputValues(productsList)

      const productStockList = await getAllProductsApi("", "", "")
      setProductStockList(productStockList)

      console.log("productStockList", productStockList)
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <ToastContainer />
      <div
        className="mt-8 overflow-x-scroll xl:overflow-x-hidden"
        style={{
          overflow: "scroll",
          height: "80vh",
        }}
      >
        <table className="w-full">
          <thead>
            <tr>
              <th className="border-b border-gray-200 pr-20 pb-[10px] text-end dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Produit</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] text-end dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Stock</p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] text-end dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Quantité demandée
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] text-end dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">
                  Quantité sortie
                </p>
              </th>
              <th className="border-b border-gray-200 pr-20 pb-[10px] text-end dark:!border-navy-700">
                <p className="text-xs tracking-wide text-gray-600">Unité</p>
              </th>
            </tr>
          </thead>

          {inputValues[0] && (
            <tbody>
              {inputValues.map((el, index) => (
                <tr>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">{el.productName}</p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-sm font-bold text-navy-700">
                      <select
                        className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                        onChange={e => handleDepotInputChange(index, el.productName, e.target.value, el.unity, el.quantiteByUnity, el.Code)}
                        value={inputValues.city}
                      >
                        <option value="">
                          Selectioner le stock
                        </option>
                        {/* {el.Stock.map((el) => (
                          <option value={el.City}>
                            {el?.City} [{el?.Disponible}]
                          </option>
                        ))} */}
                        {
                          productStockList.find(product => product.Name.trim() === el.productName.trim())?.Stock.map(stock => (
                            <option value={stock.City}>
                              {stock?.City} [{stock?.Disponible}]
                            </option>
                          ))
                        }
                      </select>
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-center text-sm font-bold text-navy-700">
                      {el.quantiteByUnity} {el.unity}
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <input
                      type="number"
                      id=""
                      placeholder="Quantité"
                      className={`flex w-full items-center justify-center rounded-xl border bg-white/0 p-2 text-sm outline-none`}

                      onChange={e => handleQuantityInputChange(index, el.productName, inputValues[index].city, el.unity, e.target.value, el.Code)}
                      value={inputValues.quantiteByUnity}
                    />
                    <p className="text-right text-lg font-bold text-navy-700">
                      Total en {el.unity} :
                    </p>
                  </td>
                  <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                    <p className="text-left text-sm font-bold text-navy-700" style={{ position: 'relative', left: '2rem' }}>
                      {el.unity}
                    </p>
                    <p className="text-left text-lg font-bold text-navy-700" style={{ position: 'relative', top: 10, left: 5 }}>
                      {parseFloat(el.inputQuantity).toFixed(2)}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          <tfoot>
            <tr>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-sm font-bold text-navy-700"></p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-lg font-bold text-navy-700">
                  Total :
                </p>
              </td>
              <td className="pt-[14px] pb-[18px] sm:text-[14px]">
                <p className="text-center text-lg font-bold text-navy-700">
                  {inputValues.reduce((sum, el) => sum + parseFloat(el.inputQuantity), 0).toFixed(2)}
                </p>
              </td>
            </tr>
          </tfoot>
        </table>

        {
          isLoading ?
          <div className="flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div> :
          <Button
            variant="gradient"
            color="#8bc34a"
            className="mr-1"
            onClick={handleStockValidation}
          >
          <span>Valider</span>
          </Button>
        }
      </div>
    </div>
  );
}

export default StockInfo;

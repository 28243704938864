import { createContext, useState, useEffect } from 'react';
import { isAuthenticatedApi } from "../api/superAdmin";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isAuth = async() => {
    try {
      const response = await isAuthenticatedApi()
      const isAuthResponse = response ? true : false
      setIsAuthenticated(isAuthResponse)
    }
    catch(err) {
      setIsAuthenticated(false)
    }
    finally {
      setIsLoading(false); // Set loading to false after API call completes
    }
  }

  useEffect(() => {
    isAuth()
  }, [isAuthenticated]);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

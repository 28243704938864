/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import API_URL from 'config';

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";
import { useEffect, useState } from "react";

import { getCookie } from "utils/cookies";
import { getOneOperatorApi } from "api/operator";

import { Spinner } from "@material-tailwind/react";

import companyLogo from "assets/img/auth/auth.png";

const Sidebar = ({ open, onClose }) => {
  const [listOfRoutes, setListOfRoutes] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const [sideBarName, setSideBarName] = useState("Administration")

  const [isAdmin, setIsAdmin] = useState(false)
  const [userImg, setUserImg] = useState("")

  useEffect(() => {
    getInitialData()
  }, [])
  
  const getInitialData = async () => {
    try {
      setIsLoading(true)

      const id = getCookie("3F2Mb2pJ1YRro5Z4")
      const adminRole = getCookie("lv9u765qzk1gr4fn1e")

      if(adminRole === "FALSE") {
        setIsAdmin(false)
        const response = await getOneOperatorApi(id)
        const idsList = response.userFromRole.access.map(el => el.value._id)

        if(response?.imageProfile?.path)
          setUserImg(response.imageProfile.path)

        const listOfAuthorizedIds = []

        routes.map(el => {
          if(idsList.includes(el.id)) {
            listOfAuthorizedIds.push(el)
          }
        })

        setListOfRoutes(listOfAuthorizedIds)
        setSideBarName(response.Name)
      }
      else if(adminRole === "TRUE") {
        setIsAdmin(true)
        setListOfRoutes(routes)
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div
      className={`w-[300px] sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span   
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      {
        isLoading ?
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <div className={`mx-[50px] mt-[50px] flex justify-center`}>
          <div className="font-poppins text-[16px] font-medium uppercase text-navy-700 dark:text-white">
            {/* HORIZON <span class="font-medium">FREE</span> */}
            {/* TIKNOLOGIA */}
            <img className="h--[100px] w-[200px] object-contain mb-2 companyLogo" src={companyLogo} alt="" style={{width: "100%", height: "100px"}} />
            {/* {
              !isAdmin &&
              <img className="h-full w-full" src={`${API_URL}/${userImg}`} alt="" style={{width: "100%", height: "100px"}} />
            } */}
            {sideBarName}
          </div>
        </div>
      }

      <div class="mt-[20px] mb-6 h-px bg-gray-300 dark:bg-white/30"></div>
      {/* Nav item */}

      {
        isLoading ?
        <div className="flex items-center justify-center">
          <Spinner className="h-12 w-12" />
        </div> :
        <ul className="pt-1 side_bar">
          <Links routes={listOfRoutes} />
        </ul>
      }

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        {/* <SidebarCard /> */}
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;

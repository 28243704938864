import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  Button,
  CardFooter,
} from "@material-tailwind/react";

import ReportDetails from "./ReportDetails";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { updateCompanyFeesDgStatusApi } from "api/companyFees";
import { updateCompanyFeesTcpStatusApi } from "api/companyFees";

function FeesCard({ tableData, accessList, showToast, isAdmin, typeOfOp }) {
  const [openReportDetails, setOpenReportDetails] = useState(false);
  const [reportDetailsData, setReportDetailsData] = useState([])

  useEffect(() => {
    getTableData();
  }, [tableData]);

  const getTableData = async () => {
    try {
      console.log("tableData", tableData);
      console.log("accessList", accessList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenReportDetails = (data) => {
    setReportDetailsData(data)
    if (accessList.Read)
      setOpenReportDetails(!openReportDetails);
  };

  const handleValidateReport = (data) => {
    const validateObjectData = {
      cvValidationStaus: "N",
      fdValidationStatus: "N",
      tlValidationStatus: "N",
    };

    if (data.typeOfOp === "CV") {
      validateObjectData.cvValidationStaus = "V";
    } else if (data.typeOfOp === "FD") {
      validateObjectData.fdValidationStatus = "V";
    } else if (data.typeOfOp === "TL") {
      validateObjectData.tlValidationStatus = "V";
    }

    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment valider cette opération ?",
          confirmButtonText: "Valider",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await updateCompanyFeesTcpStatusApi(validateObjectData, data._id);
              Swal.fire("Validée", "", "success");
              showToast();
            } catch (err) {
              console.log(err)
              Swal.fire(err?.response?.data?.error, "", "error");
            } finally {
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleCancelReport = (data) => {
    const validateObjectData = {
      cvValidationStaus: "N",
      fdValidationStatus: "N",
      tlValidationStatus: "N",
    };

    if (data.typeOfOp === "CV") {
      validateObjectData.cvValidationStaus = "A";
    } else if (data.typeOfOp === "FD") {
      validateObjectData.fdValidationStatus = "A";
    } else if (data.typeOfOp === "TL") {
      validateObjectData.tlValidationStatus = "A";
    }

    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment annuler cette opération ?",
          confirmButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await updateCompanyFeesTcpStatusApi(validateObjectData, data._id);
              Swal.fire("Annulée", "", "success");
              showToast();
            } catch (err) {
              console.log(err)
              Swal.fire(err?.response?.data?.error, "", "error");
            } finally {
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleValidateDgReport = (status, feeId) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment valider cette opération ?",
          confirmButtonText: "Valider",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await updateCompanyFeesDgStatusApi(status, feeId);
              Swal.fire("Validée", "", "success");
              showToast();
            } catch (err) {
              Swal.fire(err?.response?.data?.error, "", "error");
            } finally {
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleCancelDgReport = (status, feeId) => {
    try {
      withReactContent(Swal)
        .fire({
          icon: "warning",
          title: "Voulez-vous vraiment annuler cette opération ?",
          confirmButtonText: "Annuler",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await updateCompanyFeesDgStatusApi(status, feeId);
              Swal.fire("Annulée", "", "success");
              showToast();
            } catch (err) {
              Swal.fire(err?.response?.data?.error, "", "error");
            } finally {
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const renderCvFooter = (el) => {
    return el.cvValidationStaus === "V" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#8bc34a" className="mr-1" disabled>
          <span>Validée</span>
        </Button>
      </CardFooter>
    ) : el.cvValidationStaus === "A" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#cf503f" className="mr-1" disabled>
          <span>Annulée</span>
        </Button>
      </CardFooter>
    ) : (
      <CardFooter className="flex pt-0">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleValidateReport(el)}
          className="mr-1"
        >
          <span>Valider</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelReport(el)}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </CardFooter>
    );
  };
  const renderFdFooter = (el) => {
    return el.fdValidationStatus === "V" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#8bc34a" className="mr-1" disabled>
          <span>Validée</span>
        </Button>
      </CardFooter>
    ) : el.fdValidationStatus === "A" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#cf503f" className="mr-1" disabled>
          <span>Annulée</span>
        </Button>
      </CardFooter>
    ) : (
      <CardFooter className="flex pt-0">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleValidateReport(el)}
          className="mr-1"
        >
          <span>Valider</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelReport(el)}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </CardFooter>
    );
  };
  const renderFtlFooter = (el) => {
    return el.tlValidationStatus === "V" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#8bc34a" className="mr-1" disabled>
          <span>Validée</span>
        </Button>
      </CardFooter>
    ) : el.tlValidationStatus === "A" ? (
      <CardFooter className="flex pt-0">
        <Button variant="gradient" color="#cf503f" className="mr-1" disabled>
          <span>Annulée</span>
        </Button>
      </CardFooter>
    ) : (
      <CardFooter className="flex pt-0">
        <Button
          variant="gradient"
          color="#8bc34a"
          onClick={() => handleValidateReport(el)}
          className="mr-1"
        >
          <span>Valider</span>
        </Button>
        <Button
          variant="text"
          color="#cf503f"
          onClick={() => handleCancelReport(el)}
          className="mr-1"
        >
          <span>Annuler</span>
        </Button>
      </CardFooter>
    );
  };

  return (
    <>
      <Dialog
        size="xl"
        open={openReportDetails}
        handler={handleOpenReportDetails}
      >
        <DialogHeader>
          <p className="w-full text-center"></p>
        </DialogHeader>
        <DialogBody>
          <ReportDetails typeOf="MOROCCO" reportDetailsData={reportDetailsData} />
        </DialogBody>
      </Dialog>

      {isAdmin
        ? tableData.map((el) => (
            <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
              <CardBody onClick={() => handleOpenReportDetails(el)}>
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="mb-2 dark:text-white"
                >
                  {el.responsable.Name}
                </Typography>
                <Typography>{el.details}</Typography>
                <Typography>
                  {el.typeOfOp == "CV"
                    ? "Charges véhicules"
                    : el.typeOfOp == "FD"
                    ? "Frais de déplacement"
                    : el.typeOfOp == "TL"
                    ? "Frais de transport local"
                    : ""}
                </Typography>
                <Typography>{el.montant} DH</Typography>
                <Typography>{el.typeOfOp != "TL" ? el?.date?.split("T")[0] : el.formatedDate}</Typography>
                {
                  el.typeOfOp == "TL" &&
                  <Typography>{el?.fromCity?.Name || el?.startedPoint} - {el?.toCity?.Name || el?.endPoint}</Typography>
                }
              </CardBody>
              {el.dgValidationStatus === "V" ? (
                <CardFooter className="flex pt-0">
                  <Button
                    variant="gradient"
                    color="#8bc34a"
                    className="mr-1"
                    disabled
                  >
                    <span>Validée</span>
                  </Button>
                </CardFooter>
              ) : el.dgValidationStatus === "A" ? (
                <CardFooter className="flex pt-0">
                  <Button
                    variant="gradient"
                    color="#cf503f"
                    className="mr-1"
                    disabled
                  >
                    <span>Annulée</span>
                  </Button>
                </CardFooter>
              ) : (
                <CardFooter className="flex pt-0">
                  <Button
                    variant="gradient"
                    color="#8bc34a"
                    onClick={() => handleValidateDgReport("V", el._id)}
                    className="mr-1"
                  >
                    <span>Valider</span>
                  </Button>
                  <Button
                    variant="text"
                    color="#cf503f"
                    onClick={() => handleCancelDgReport("A", el._id)}
                    className="mr-1"
                  >
                    <span>Annuler</span>
                  </Button>
                </CardFooter>
              )}
            </Card>
          ))
        : tableData.map((el) => (
            <Card className="mt-6 w-full cursor-pointer dark:bg-indigo-900">
              <CardBody onClick={() => handleOpenReportDetails(el)}>
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className="mb-2 dark:text-white"
                >
                  {el.responsable.Name}
                </Typography>
                <Typography>{el.details}</Typography>
                <Typography>
                  {el.typeOfOp == "CV"
                    ? "Charges véhicules"
                    : el.typeOfOp == "FD"
                    ? "Frais de déplacement"
                    : el.typeOfOp == "TL"
                    ? "Frais de transport local"
                    : ""}
                </Typography>
                <Typography>{el.montant} DH</Typography>
                <Typography>{el?.date?.split("T")[0]}</Typography>
              </CardBody>
              {typeOfOp == "CV"
                ? renderCvFooter(el)
                : typeOfOp == "FD"
                ? renderFdFooter(el)
                : renderFtlFooter(el)}
            </Card>
          ))}
    </>
  );
}

export default FeesCard;

import React, { useEffect, useState } from 'react'

import {
    Button,
  } from "@material-tailwind/react";
import { getOneReportDetailsApi } from 'api/gestionStock';
import { updateRecouvrementDate } from 'api/recovry';

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function EditeRecouvryDate({selectedReportId, selectedRecovryInfo, selectedRecovryType, updateData}) {
    const [selectedDate, setSelectedDate] = useState("")

    useEffect(() => {
        getInitialData();
    }, [selectedRecovryInfo]);

    const getInitialData = async () => {
        try {
            console.log("selectedReportId", selectedReportId)

            const Year = (new Date(selectedRecovryInfo.DateEcheance).getFullYear())
            const Month = (new Date(selectedRecovryInfo.DateEcheance).getMonth()+1).toString().padStart(2, '0')
            const Day = (new Date(selectedRecovryInfo.DateEcheance).getDate()).toString().padStart(2, '0')

            // const selectedDateObject = new Date(Year, Month, Day)
            setSelectedDate(`${Year}-${Month}-${Day}`)
        }
        catch(err) {
            console.log(err)
        }
    }

    const handleDateChange = (e) => {
        try {
            const selectedDate = e.target.value;
            setSelectedDate(selectedDate);
        }
        catch(err) {
            console.log(err)
        }
    }

    const handleEditeRecovryDate = async () => {
        try {
            const reportDetails = await getOneReportDetailsApi(selectedReportId);

            const [year, month, day] = selectedDate.split('-')

            if(selectedRecovryType === "Cheque") {
                reportDetails.Rapport.recouvrement.Cheque.ChequeInfo.DateEcheance = `${day}-${month}-${year}`
                reportDetails.Rapport.recouvrement.Cheque.ChequeInfo[selectedRecovryInfo.key].DateEcheance = `${year}-${month}-${day}`

                if(reportDetails.Rapport.recouvrement.Cheque.ChequeInfo[selectedRecovryInfo.key].DateList) {
                    reportDetails.Rapport.recouvrement.Cheque.ChequeInfo[selectedRecovryInfo.key].DateList.push(`${day}-${month}-${year}`)
                }
                else {
                    reportDetails.Rapport.recouvrement.Cheque.ChequeInfo[selectedRecovryInfo.key].DateList = [`${day}-${month}-${year}`]
                }
            }
            else {
                reportDetails.Rapport.recouvrement.Effet.EffetInfo.DateEcheance = `${day}-${month}-${year}`
                reportDetails.Rapport.recouvrement.Effet.EffetInfo[selectedRecovryInfo.key].DateEcheance = `${year}-${month}-${day}`

                if(reportDetails.Rapport.recouvrement.Effet.EffetInfo[selectedRecovryInfo.key].DateList) {
                    reportDetails.Rapport.recouvrement.Effet.EffetInfo[selectedRecovryInfo.key].DateList.push(`${day}-${month}-${year}`)
                }
                else {
                    reportDetails.Rapport.recouvrement.Effet.EffetInfo[selectedRecovryInfo.key].DateList = [`${day}-${month}-${year}`]
                }
            }

            withReactContent(Swal)
            .fire({
              icon: "warning",
              title: "Voulez-vous vraiment modifier cette date ?",
              confirmButtonText: "Modifier",
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                await updateRecouvrementDate(selectedReportId, reportDetails.Rapport)

                updateData()
                Swal.fire("Modifiée", "", "success");
              }
            });
        }
        catch (err) {
            console.log(err)
        }
    }

  return (
    <div>
        <form style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <input type="date" value={selectedDate} onChange={handleDateChange} />
                <Button
                    variant="gradient"
                    color="#8bc34a"
                    onClick={() => handleEditeRecovryDate()}
                    className="mr-1"
                >
                    <span>Modifier</span>
                </Button>
            </div>
        </form>
    </div>
  )
}

export default EditeRecouvryDate
